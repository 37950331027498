import React, {useState, useEffect} from 'react';
import Aux from "../../../hoc/Auxiliary";
import {Button, Container, Row, Col, Form, Tabs, Tab, InputGroup, Image} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Select from 'react-select';
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import {mainAxios} from "../../../components/Axios/axios";
import DatePicker from "react-datepicker";
import userImage from '../../../assets/img/user.png';
import moment from 'moment';
import {uid} from 'react-uid';


import "react-datepicker/dist/react-datepicker.css";

function CreateEmployee() {


    const familyConditionOptions = [
        {value: "MARRIED", label: "Evli"},
        {value: 'SINGLE', label: 'Subay'},
        {value: 'WIDOW', label: 'Dul'},
        {value: 'DIVORCED', label: 'Boşanmış'},
    ];

    const educationTypeOptions = [
        {value: "VISUAL", label: "Əyani"},
        {value: 'CORRESPONDENCE', label: 'Qiyabi'},
    ]

    const driverLicenceOptions = [
        {value: 'A', label: "A"},
        {value: 'B', label: "B"},
        {value: 'C', label: "C"},
        {value: 'D', label: "D"},
    ]

    const genderOptions = [
        {value: 'MALE', label: "Kişi"},
        {value: 'FEMALE', label: "Qadın"},
        {value: 'NON', label: "Tələb yoxdur"},
    ]

    const bloodTypeOptions = [
        {value: "ONE", label: 'I(O)'},
        {value: "TWO", label: 'II(A)'},
        {value: "THREE", label: 'III(B)'},
        {value: "FOUR", label: 'IV(AB)'},
    ];

    const relationTypeOptions = [
        {value: "FATHER", label: 'Ata'},
        {value: "MOTHER", label: 'Ana'},
        {value: "SISTER", label: 'Bacı'},
        {value: "BROTHER", label: 'Qardaş'},
    ]

    const [key, setKey] = useState('home');
    const [startIdDate, setStartIdDate] = useState(new Date());
    const [expiredIdDate, setExpiredIdDate] = useState(new Date());
    const [startBirthDate, setStartBirthDate] = useState(new Date());
    const [startPassportDate, setStartPassportDate] = useState(new Date());
    const [expiredPassportDate, setExpiredPassportDate] = useState(new Date());
    const [familyMemberBirthDate, setFamilyMemberBirthDate] = useState(new Date());
    const [startAcademicDegreeDate, setStartAcademicDegreeDate] = useState(new Date());

    /*Company*/

    const [startJobDate, setStartJobDate] = useState(new Date());
    const [endJobDate, setEndJobDate] = useState(new Date());

    /*Education*/

    const [startGraduateDate, setStartGraduateDate] = useState(new Date());
    const [endGraduateDate, setEndGraduateDate] = useState(new Date());
    const [startGraduateFile, setStartGraduateFile] = useState(new Date());
    const [expiredCertificateDate, setExpiredCertificateDate] = useState(new Date());
    const [startRewardDate, setStartRewardDate] = useState(new Date());
    const [expiredDriverLicenceDate, setExpiredDriverLicenceDate] = useState(new Date());


    const [idCardNumber, setIdCardNumber] = useState('');
    const [idCardPin, setIdCardPin] = useState('');
    const [idCardSerial, setIdCardSerial] = useState('');
    const [fullName, setFullName] = useState('');
    const [countryBirth, setCountryBirth] = useState('');
    const [countryCitizen, setCountryCitizen] = useState('');
    const [livePermission, setLivePermission] = useState('');
    const [idCardOrganization, setIdCardOrganization] = useState('');
    const [passportNumber, setPassportNumber] = useState('');
    const [passportSerial, setPassportSerial] = useState('');
    const [settlement, setSettlement] = useState('');
    const [street, setStreet] = useState('');
    const [block, setBlock] = useState('');
    const [apartment, setApartment] = useState('');
    const [home, setHome] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [mobileNumber1, setMobileNumber1] = useState('');
    const [mobileNumber2, setMobileNumber2] = useState('');
    const [businessPhone, setBusinessPhone] = useState('');
    const [businessInternalPhone, setBusinessInternalPhone] = useState('');
    const [email, setEmail] = useState('');
    const [emailBusiness, setEmailBusiness] = useState('');
    const [academicDegreeNumber, setAcademicDegreeNumber] = useState('');
    const [academicDegreeOrganization, setAcademicDegreeOrganization] = useState('');
    /*    const [familyMember, setFamilyMember] = useState('');
        const [familyMemberFullName, setFamilyMemberFullName] = useState('');
        const [familyMemberBirthPlace, setFamilyMemberBirthPlace] = useState('');
        const [familyMemberWorkPlace, setFamilyMemberWorkPlace] = useState('');
        const [familyMemberProfession, setFamilyMemberProfession] = useState('');
        const [familyMemberAddress, setFamilyMemberAddress] = useState('');*/
    const [dataVal, setDataVal] = useState('');
    const [file, setFile] = useState(userImage);
    const [uploadFile, setUploadFile] = useState();


    /*Company*/

    const [company, setCompany] = useState('');
    const [section, setSection] = useState('');
    const [subSection, setSubSection] = useState('');
    const [employeePosition, setEmployeePosition] = useState('');
    const [firedReason, setFiredReason] = useState('');
    const [checked, setChecked] = useState(true);
    const [checkPrisoner, setCheckPrisoner] = useState(true);
    const [checkColleague, setCheckColleague] = useState(true);

    /*Education*/

    const [faculty, setFaculty] = useState('');
    const [direction, setDirection] = useState('');
    const [major, setMajor] = useState('');
    const [degree, setDegree] = useState('');
    const [graduateFileNumber, setGraduateFileNumber] = useState('');
    const [nostrificationNumber, setNostrificationNumber] = useState('');
    const [certificate, setCertificate] = useState('');
    const [warrantyNumber, setWarrantyNumber] = useState('');


    const [familyMemberArr, setFamilyMemberArr] = useState([{
        address: '',
        birthday: '',
        birthplace: '',
        fullName: '',
        position: '',
        relationType: '',
        workPlace: ''
    }]);

    const [city, setCity] = useState([]);
    const [region, setRegion] = useState([]);
    const [country, setCountry] = useState([]);
    const [university, setUniversity] = useState([]);
    const [quota, setQuota] = useState([]);
    const [quotaArr, setQuotaArr] = useState([])
    const [reward, setReward] = useState([]);
    const [rewardOrganization, setRewardOrganization] = useState([]);
    const [certificateArr, setCertificateArr] = useState([{
        endDate: '',
        name: ''
    }]);

    const [rewardArr, setRewardArr] = useState([{
        name: '',
        organization: '',
        startDate: ''
    }])

    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [selectedFamilyCondition, setSelectedFamilyCondition] = useState(null);
    const [selectedGender, setSelectedGender] = useState(null);
    const [selectedBloodType, setSelectedBloodType] = useState(null);
    const [selectedRelationType, setSelectedRelationType] = useState(null);
    const [selectedUniversity, setSelectedUniversity] = useState(null);
    const [selectedEducationType, setSelectedEducationType] = useState(null);
    const [selectedDriverLicence, setSelectedDriverLicence] = useState(null);
    const [selectedQuota, setSelectedQuota] = useState(null);
    const [selectedReward, setSelectedReward] = useState(null);
    const [selectedRewardOrganization, setSelectedRewardOrganization] = useState(null);


    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: '#040647',
            backgroundColor: state.isSelected ? '#F3F8FF' : 'transparent',
            padding: '10px',
            margin: '0',
            fontSize: '16px',
            "&:first-of-type": {
                borderRadius: '2px 2px 0 0',
            },
            "&:hover": {
                backgroundColor: '#FAFCFF',
            },
            "&:last-child": {
                borderBottom: 'none',
                borderRadius: '0 0 2px 2px',
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative'

        }),

        indicatorSeparator: () => {
        },

        indicatorsContainer: (provided, state) => ({
            ...provided,
        }),

        control: (provided) => ({
            ...provided,
            minHeight: '43px',
            fontSize: '14px',
            padding: '0',
            margin: '0',
            color: '#66615b',
            backgroundColor: '#FAFCFF',
            boxShadow: 'none',
            border: '1px solid rgba(4, 6, 71, 0.1)',
            "&:hover": {
                borderColor: 'rgba(4, 6, 71, 0.1)',
            },

        }),

        container: (provided) => ({
            ...provided,
            width: '100%',
        }),


        menu: (provided) => ({
            ...provided,
            borderRadius: '2px',
            padding: '0',
            margin: '0',
            borderColor: 'red',
            width: '100%'
        }),

        dropdownIndicator: defaultStyles => ({
            ...defaultStyles,
            'svg path': {
                fill: 'rgba(24,24,24, .8)',
            },

            'svg': {
                width: '18px'
            },
        }),

        menuList: base => ({
            ...base,
            padding: 0,
            borderColor: 'red'

        })

    };

    const uploadImage = (event) => {
        setFile(URL.createObjectURL(event.target.files[0]));
        setUploadFile(event.target.files[0])
    }

    const removeImage = () => {
        setFile(userImage)
    }

    const getCity = () => {
        mainAxios({
            method: 'get',
            url: '/address/city',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        }).then((res) => {
            setCity(res.data.data)
        });
    }

    const getCountry = () => {
        mainAxios({
            method: 'get',
            url: '/address/country',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        }).then((res) => {
            setCountry(res.data.data)
        });
    }

    const getRegion = () => {
        mainAxios({
            method: 'get',
            url: '/address/district',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        }).then((res) => {
            setRegion(res.data.data)
        });
    }

    const getUniversity = () => {
        mainAxios({
            method: 'get',
            url: '/institution',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        }).then((res) => {

            setUniversity(res.data.data)
        });
    }

    const getQuota = () => {
        mainAxios({
            method: 'get',
            url: '/employee/quota',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        }).then((res) => {
            setQuota(res.data.data)
        });
    }

    const getReward = () => {
        mainAxios({
            method: 'get',
            url: '/government-achievement',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        }).then((res) => {
            setReward(res.data.data)
        });
    }

    const getRewardOrganization = () => {
        mainAxios({
            method: 'get',
            url: '/government-achievement/organizations',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        }).then((res) => {
            setRewardOrganization(res.data.data)
        });
    }

    const addFamilyMember = () => {
        setFamilyMemberArr(familyMemberArr => [...familyMemberArr, {
            address: '',
            birthday: '',
            birthplace: '',
            fullName: '',
            position: '',
            relationType: '',
            workPlace: ''
        }]);

        console.log(familyMemberArr)
    }

    const addCertificate = () => {
        setCertificateArr(certificateArr => [...certificateArr, {
            endDate: '',
            name: ''
        }])
    }

    const addReward = () => {
        setRewardArr(rewardArr => [...rewardArr, {
            name: '',
            organization: '',
            startDate: ''
        }])
    }

    const removeItem = (index) => {
        let arr = familyMemberArr.filter((_, i) => i !== index);
        setFamilyMemberArr([...arr], arr);

    }


    const sendData = () => {
        let data = {
            "addressApartment": apartment,
            "addressBlock": block,
            "addressCityId": selectedCity !== null ? selectedCity.value : "",
            "addressCountryId": selectedCountry !== null ? selectedCountry.value : "",
            "addressDistrictId": selectedRegion !== null ? selectedRegion.value : "",
            "addressHome": home,
            "addressStreet": street,
            "addressVillage": settlement,
            "birthday": moment(startBirthDate).format("DD-MM-YYYY"),
            "birthplace": countryBirth,
            "bloodGroup": selectedBloodType !== null ? selectedBloodType.value : "",
            "businessMailAddress": emailBusiness,
            "businessPhone": businessPhone,
            "citizenCountry": countryCitizen,
            "familyCondition": selectedFamilyCondition !== null ? selectedFamilyCondition.value : "",
            "familyMembers": familyMemberArr,
            "foreignPassportEndDate": moment(expiredPassportDate).format("DD-MM-YYYY"),
            "foreignPassportNumber": passportNumber,
            "foreignPassportSeries": passportSerial,
            "foreignPassportStartDate": moment(startPassportDate).format("DD-MM-YYYY"),
            "fullName": fullName,
            "gender": selectedGender !== null ? selectedGender.value : "",
            "homePhone": phoneNumber,
            "idcardEndDate": moment(expiredIdDate).format("DD-MM-YYYY"),
            "idcardNumber": idCardNumber,
            "idcardOrganization": idCardOrganization,
            "idcardPin": idCardPin,
            "idcardSeries": idCardSerial,
            "idcardStartDate": moment(startIdDate).format("DD-MM-YYYY"),
            "internalBusinessPhone": businessInternalPhone,
            "mobilePhone1": mobileNumber1,
            "mobilePhone2": mobileNumber2,
            "ownMailAddress": email,
            "permission": livePermission
        }

        mainAxios({
            method: 'post',
            url: '/employee',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data: data
        }).then((res) => {
            setKey('company');
            setDataVal(res.data.data);
            SenDataImage(res.data.data)
        });

    }

    const SenDataImage = (id) => {
        const formData = new FormData();
        formData.append("file", uploadFile);
        formData.append('id', id)
        mainAxios({
            method: 'post',
            url: '/image/save',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data: formData
        }).then((res) => {
        });
    }

    const sendDataBusiness = () => {
        let data = {
            "company": company,
            "jobEndDate": moment(endJobDate).format("DD-MM-YYYY"),
            "jobEndReason": firedReason,
            "jobStartDate": moment(startJobDate).format("DD-MM-YYYY"),
            "mainJob": checked,
            "position": employeePosition,
            "section": section,
            "subSection": subSection
        }

        mainAxios({
            method: 'put',
            url: '/employee/business-info/' + dataVal,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data: data
        }).then((res) => {
            setKey('education');
        });
    }

    const sendDataAcademic = () => {
        let arr = []
        for (let i of quotaArr) {
            arr.push(i.value)
        }
        let data = {
            "academicDegreeDate": moment(startAcademicDegreeDate).format("DD-MM-YYYY"),
            "academicDegreeNumber": academicDegreeNumber,
            "academicDegreeOrganization": academicDegreeOrganization,
            "certificates": certificateArr,
            "degree": degree,
            "direction": direction,
            "driverCardCategory": selectedDriverLicence !== null ? selectedDriverLicence.value : "",
            "driverCardEndDate": moment(expiredDriverLicenceDate).format("DD-MM-YYYY"),
            "educationType": selectedEducationType !== null ? selectedEducationType.value : "",
            "entranceDate": moment(startGraduateDate).format("DD-MM-YYYY"),
            "faculty": faculty,
            "governmentAchievements": rewardArr,
            "graduateDate": moment(endGraduateDate).format("DD-MM-YYYY"),
            "graduateFileDate": moment(startGraduateFile).format("DD-MM-YYYY"),
            "graduateFileNumber": graduateFileNumber,
            "institution": selectedUniversity !== null ? selectedUniversity.key : "",
            "memberOfColleaguesAlliance": checkColleague,
            "nostrifikasiyaNumber": nostrificationNumber,
            "prisoner": checkPrisoner,
            "quotas": arr,
            "speciality": major
        }

        mainAxios({
            method: 'put',
            url: '/employee/academic-info/' + dataVal,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            data: data
        }).then((res) => {
            window.location.href = "/employeeSchedule"
        });
    }

    useEffect(() => {
        getCity();
        getCountry();
        getRegion();
        getUniversity();
        getQuota();
        getReward();
        getRewardOrganization();
    }, []);

    return (
        <Aux>
            <Header/>
            <Sidebar/>
            <div className="main">
                <div className="create-staff">
                    <Container fluid>
                        <div className="title-block flex">
                            <div className="title flex-center">
                                <Link to="/staffSchedule" className="flex">
                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M23.3333 14H7.58333M12.25 8.75L7 14L12.25 19.25" stroke="#193651"
                                              strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </Link>
                                İşçi əlavə et
                            </div>
                        </div>
                        <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
                            <Tab eventKey="home" title="Ümumi məlumatlar" disabled={key !== "home"}>
                                <div className="block">
                                    <Form className="form-list">
                                        <div className="add-block">
                                            <div className="block-inn">
                                                <div className="block-title">
                                                    Şəxsiyyət vəsiqəsi forması
                                                </div>
                                                <div className="upload-content flex-center">
                                                    <div className="upload-img">
                                                        <Image src={file}/>
                                                    </div>
                                                    <div className="btn-block flex-center">
                                                        <button className="btn-border add-img" type="button">
                                                            Şəkil əlavə et
                                                            <input type="file"
                                                                   onChange={(event) => uploadImage(event)}/>
                                                        </button>
                                                        <button className="btn-border remove-img" type="button"
                                                                onClick={() => removeImage()}>
                                                            Şəkli sil
                                                        </button>
                                                    </div>
                                                </div>
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">Seriya və nömrə *</span>
                                                            <InputGroup>
                                                                <Form.Control className="input-add" placeholder="AZE"
                                                                              value={idCardSerial}
                                                                              onChange={(e => setIdCardSerial(e.target.value))}/>
                                                                <Form.Control placeholder="Nömrə daxil edin"
                                                                              value={idCardNumber}
                                                                              onChange={(e => setIdCardNumber(e.target.value))}/>
                                                            </InputGroup>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">FİN kod *</span>
                                                            <Form.Label>
                                                                <Form.Control placeholder="FIN kodu daxil edin"
                                                                              value={idCardPin}
                                                                              onChange={(e => setIdCardPin(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">Ailə vəziyyəti</span>
                                                            <Select
                                                                placeholder="Ailə vəziyyəti seçin"
                                                                value={selectedFamilyCondition}
                                                                onChange={(val) => {
                                                                    setSelectedFamilyCondition(val);
                                                                }}
                                                                options={familyConditionOptions}
                                                                getOptionLabel={(option) => (option.label)}
                                                                styles={customStyles}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span
                                                                className="input-title">Şəxsiy. vəs. verilmə tarixi *</span>
                                                            <Form.Label className="relative m-0">
                                                                <DatePicker selected={startIdDate}
                                                                            dateFormat="dd-MM-yyyy"
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                            onChange={(date) => setStartIdDate(date)}/>
                                                                <Button className="btn-transparent">
                                                                    <svg width="18" height="18"
                                                                         viewBox="0 0 18 18" fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <g opacity="0.8"
                                                                           clipPath="url(#clip0)">
                                                                            <path
                                                                                d="M5.34327 8.75391H4.25583C3.97432 8.75391 3.74609 8.99002 3.74609 9.28125C3.74609 9.57248 3.97432 9.80859 4.25583 9.80859H5.34327C5.62478 9.80859 5.853 9.57248 5.853 9.28125C5.853 8.99002 5.62478 8.75391 5.34327 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M5.34327 11.0039H4.25583C3.97432 11.0039 3.74609 11.24 3.74609 11.5312C3.74609 11.8225 3.97432 12.0586 4.25583 12.0586H5.34327C5.62478 12.0586 5.853 11.8225 5.853 11.5312C5.853 11.24 5.62478 11.0039 5.34327 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M5.34327 13.2539H4.25583C3.97432 13.2539 3.74609 13.49 3.74609 13.7812C3.74609 14.0725 3.97432 14.3086 4.25583 14.3086H5.34327C5.62478 14.3086 5.853 14.0725 5.853 13.7812C5.853 13.49 5.62478 13.2539 5.34327 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 8.75391H8.60349C8.32198 8.75391 8.09375 8.99002 8.09375 9.28125C8.09375 9.57248 8.32198 9.80859 8.60349 9.80859H9.69092C9.97243 9.80859 10.2007 9.57248 10.2007 9.28125C10.2007 8.99002 9.97243 8.75391 9.69092 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 11.0039H8.60349C8.32198 11.0039 8.09375 11.24 8.09375 11.5312C8.09375 11.8225 8.32198 12.0586 8.60349 12.0586H9.69092C9.97243 12.0586 10.2007 11.8225 10.2007 11.5312C10.2007 11.24 9.97243 11.0039 9.69092 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 13.2539H8.60349C8.32198 13.2539 8.09375 13.49 8.09375 13.7812C8.09375 14.0725 8.32198 14.3086 8.60349 14.3086H9.69092C9.97243 14.3086 10.2007 14.0725 10.2007 13.7812C10.2007 13.49 9.97243 13.2539 9.69092 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 8.75391H12.955C12.6735 8.75391 12.4453 8.99002 12.4453 9.28125C12.4453 9.57248 12.6735 9.80859 12.955 9.80859H14.0425C14.324 9.80859 14.5522 9.57248 14.5522 9.28125C14.5522 8.99002 14.324 8.75391 14.0425 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 11.0039H12.955C12.6735 11.0039 12.4453 11.24 12.4453 11.5312C12.4453 11.8225 12.6735 12.0586 12.955 12.0586H14.0425C14.324 12.0586 14.5522 11.8225 14.5522 11.5312C14.5522 11.24 14.324 11.0039 14.0425 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 13.2539H12.955C12.6735 13.2539 12.4453 13.49 12.4453 13.7812C12.4453 14.0725 12.6735 14.3086 12.955 14.3086H14.0425C14.324 14.3086 14.5522 14.0725 14.5522 13.7812C14.5522 13.49 14.324 13.2539 14.0425 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M16.319 2.28516H15.0956V1.40625C15.0956 1.11502 14.8674 0.878906 14.5859 0.878906C14.3044 0.878906 14.0762 1.11502 14.0762 1.40625V2.28516H9.65845V1.40625C9.65845 1.11502 9.43023 0.878906 9.14872 0.878906C8.86721 0.878906 8.63898 1.11502 8.63898 1.40625V2.28516H4.22127V1.40625C4.22127 1.11502 3.99304 0.878906 3.71153 0.878906C3.43002 0.878906 3.20179 1.11502 3.20179 1.40625V2.28516H1.97843C1.13522 2.28516 0.449219 2.99486 0.449219 3.86719V15.5391C0.449219 16.4114 1.13522 17.1211 1.97843 17.1211H16.319C17.1622 17.1211 17.8482 16.4114 17.8482 15.5391C17.8482 15.1987 17.8482 4.16338 17.8482 3.86719C17.8482 2.99486 17.1622 2.28516 16.319 2.28516ZM1.46869 3.86719C1.46869 3.57641 1.69736 3.33984 1.97843 3.33984H3.20179V4.21875C3.20179 4.50998 3.43002 4.74609 3.71153 4.74609C3.99304 4.74609 4.22127 4.50998 4.22127 4.21875V3.33984H8.63898V4.21875C8.63898 4.50998 8.86721 4.74609 9.14872 4.74609C9.43023 4.74609 9.65845 4.50998 9.65845 4.21875V3.33984H14.0762V4.21875C14.0762 4.50998 14.3044 4.74609 14.5859 4.74609C14.8674 4.74609 15.0956 4.50998 15.0956 4.21875V3.33984H16.319C16.6001 3.33984 16.8287 3.57641 16.8287 3.86719V5.94141H1.46869V3.86719ZM16.319 16.0664H1.97843C1.69736 16.0664 1.46869 15.8298 1.46869 15.5391V6.99609H16.8287V15.5391C16.8287 15.8298 16.6001 16.0664 16.319 16.0664Z"
                                                                                fill="#181818"/>
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0">
                                                                                <rect width="17.399"
                                                                                      height="18"
                                                                                      fill="white"
                                                                                      transform="translate(0.449219)"/>
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </Button>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">Şəxsiy. vəs. qüvvədə olma tarixi *</span>
                                                            <Form.Label className="relative m-0">
                                                                <DatePicker selected={expiredIdDate}
                                                                            dateFormat="dd-MM-yyyy"
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                            onChange={(date) => setExpiredIdDate(date)}/>
                                                                <Button className="btn-transparent">
                                                                    <svg width="18" height="18"
                                                                         viewBox="0 0 18 18" fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <g opacity="0.8"
                                                                           clipPath="url(#clip0)">
                                                                            <path
                                                                                d="M5.34327 8.75391H4.25583C3.97432 8.75391 3.74609 8.99002 3.74609 9.28125C3.74609 9.57248 3.97432 9.80859 4.25583 9.80859H5.34327C5.62478 9.80859 5.853 9.57248 5.853 9.28125C5.853 8.99002 5.62478 8.75391 5.34327 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M5.34327 11.0039H4.25583C3.97432 11.0039 3.74609 11.24 3.74609 11.5312C3.74609 11.8225 3.97432 12.0586 4.25583 12.0586H5.34327C5.62478 12.0586 5.853 11.8225 5.853 11.5312C5.853 11.24 5.62478 11.0039 5.34327 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M5.34327 13.2539H4.25583C3.97432 13.2539 3.74609 13.49 3.74609 13.7812C3.74609 14.0725 3.97432 14.3086 4.25583 14.3086H5.34327C5.62478 14.3086 5.853 14.0725 5.853 13.7812C5.853 13.49 5.62478 13.2539 5.34327 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 8.75391H8.60349C8.32198 8.75391 8.09375 8.99002 8.09375 9.28125C8.09375 9.57248 8.32198 9.80859 8.60349 9.80859H9.69092C9.97243 9.80859 10.2007 9.57248 10.2007 9.28125C10.2007 8.99002 9.97243 8.75391 9.69092 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 11.0039H8.60349C8.32198 11.0039 8.09375 11.24 8.09375 11.5312C8.09375 11.8225 8.32198 12.0586 8.60349 12.0586H9.69092C9.97243 12.0586 10.2007 11.8225 10.2007 11.5312C10.2007 11.24 9.97243 11.0039 9.69092 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 13.2539H8.60349C8.32198 13.2539 8.09375 13.49 8.09375 13.7812C8.09375 14.0725 8.32198 14.3086 8.60349 14.3086H9.69092C9.97243 14.3086 10.2007 14.0725 10.2007 13.7812C10.2007 13.49 9.97243 13.2539 9.69092 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 8.75391H12.955C12.6735 8.75391 12.4453 8.99002 12.4453 9.28125C12.4453 9.57248 12.6735 9.80859 12.955 9.80859H14.0425C14.324 9.80859 14.5522 9.57248 14.5522 9.28125C14.5522 8.99002 14.324 8.75391 14.0425 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 11.0039H12.955C12.6735 11.0039 12.4453 11.24 12.4453 11.5312C12.4453 11.8225 12.6735 12.0586 12.955 12.0586H14.0425C14.324 12.0586 14.5522 11.8225 14.5522 11.5312C14.5522 11.24 14.324 11.0039 14.0425 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 13.2539H12.955C12.6735 13.2539 12.4453 13.49 12.4453 13.7812C12.4453 14.0725 12.6735 14.3086 12.955 14.3086H14.0425C14.324 14.3086 14.5522 14.0725 14.5522 13.7812C14.5522 13.49 14.324 13.2539 14.0425 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M16.319 2.28516H15.0956V1.40625C15.0956 1.11502 14.8674 0.878906 14.5859 0.878906C14.3044 0.878906 14.0762 1.11502 14.0762 1.40625V2.28516H9.65845V1.40625C9.65845 1.11502 9.43023 0.878906 9.14872 0.878906C8.86721 0.878906 8.63898 1.11502 8.63898 1.40625V2.28516H4.22127V1.40625C4.22127 1.11502 3.99304 0.878906 3.71153 0.878906C3.43002 0.878906 3.20179 1.11502 3.20179 1.40625V2.28516H1.97843C1.13522 2.28516 0.449219 2.99486 0.449219 3.86719V15.5391C0.449219 16.4114 1.13522 17.1211 1.97843 17.1211H16.319C17.1622 17.1211 17.8482 16.4114 17.8482 15.5391C17.8482 15.1987 17.8482 4.16338 17.8482 3.86719C17.8482 2.99486 17.1622 2.28516 16.319 2.28516ZM1.46869 3.86719C1.46869 3.57641 1.69736 3.33984 1.97843 3.33984H3.20179V4.21875C3.20179 4.50998 3.43002 4.74609 3.71153 4.74609C3.99304 4.74609 4.22127 4.50998 4.22127 4.21875V3.33984H8.63898V4.21875C8.63898 4.50998 8.86721 4.74609 9.14872 4.74609C9.43023 4.74609 9.65845 4.50998 9.65845 4.21875V3.33984H14.0762V4.21875C14.0762 4.50998 14.3044 4.74609 14.5859 4.74609C14.8674 4.74609 15.0956 4.50998 15.0956 4.21875V3.33984H16.319C16.6001 3.33984 16.8287 3.57641 16.8287 3.86719V5.94141H1.46869V3.86719ZM16.319 16.0664H1.97843C1.69736 16.0664 1.46869 15.8298 1.46869 15.5391V6.99609H16.8287V15.5391C16.8287 15.8298 16.6001 16.0664 16.319 16.0664Z"
                                                                                fill="#181818"/>
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0">
                                                                                <rect width="17.399"
                                                                                      height="18"
                                                                                      fill="white"
                                                                                      transform="translate(0.449219)"/>
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </Button>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">Soyadı, adı, ata adı *</span>
                                                            <Form.Label>
                                                                <Form.Control type="text"
                                                                              placeholder="Ştat vahidinin saynı daxil edin"
                                                                              value={fullName}
                                                                              onChange={(e => {
                                                                                  setFullName(e.target.value);
                                                                              })}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">Doğum tarixi *</span>
                                                            <Form.Label className="relative m-0">
                                                                <DatePicker selected={startBirthDate}
                                                                            dateFormat="dd-MM-yyyy"
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                            onChange={(date) => setStartBirthDate(date)}/>
                                                                <Button className="btn-transparent">
                                                                    <svg width="18" height="18"
                                                                         viewBox="0 0 18 18" fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <g opacity="0.8"
                                                                           clipPath="url(#clip0)">
                                                                            <path
                                                                                d="M5.34327 8.75391H4.25583C3.97432 8.75391 3.74609 8.99002 3.74609 9.28125C3.74609 9.57248 3.97432 9.80859 4.25583 9.80859H5.34327C5.62478 9.80859 5.853 9.57248 5.853 9.28125C5.853 8.99002 5.62478 8.75391 5.34327 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M5.34327 11.0039H4.25583C3.97432 11.0039 3.74609 11.24 3.74609 11.5312C3.74609 11.8225 3.97432 12.0586 4.25583 12.0586H5.34327C5.62478 12.0586 5.853 11.8225 5.853 11.5312C5.853 11.24 5.62478 11.0039 5.34327 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M5.34327 13.2539H4.25583C3.97432 13.2539 3.74609 13.49 3.74609 13.7812C3.74609 14.0725 3.97432 14.3086 4.25583 14.3086H5.34327C5.62478 14.3086 5.853 14.0725 5.853 13.7812C5.853 13.49 5.62478 13.2539 5.34327 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 8.75391H8.60349C8.32198 8.75391 8.09375 8.99002 8.09375 9.28125C8.09375 9.57248 8.32198 9.80859 8.60349 9.80859H9.69092C9.97243 9.80859 10.2007 9.57248 10.2007 9.28125C10.2007 8.99002 9.97243 8.75391 9.69092 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 11.0039H8.60349C8.32198 11.0039 8.09375 11.24 8.09375 11.5312C8.09375 11.8225 8.32198 12.0586 8.60349 12.0586H9.69092C9.97243 12.0586 10.2007 11.8225 10.2007 11.5312C10.2007 11.24 9.97243 11.0039 9.69092 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 13.2539H8.60349C8.32198 13.2539 8.09375 13.49 8.09375 13.7812C8.09375 14.0725 8.32198 14.3086 8.60349 14.3086H9.69092C9.97243 14.3086 10.2007 14.0725 10.2007 13.7812C10.2007 13.49 9.97243 13.2539 9.69092 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 8.75391H12.955C12.6735 8.75391 12.4453 8.99002 12.4453 9.28125C12.4453 9.57248 12.6735 9.80859 12.955 9.80859H14.0425C14.324 9.80859 14.5522 9.57248 14.5522 9.28125C14.5522 8.99002 14.324 8.75391 14.0425 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 11.0039H12.955C12.6735 11.0039 12.4453 11.24 12.4453 11.5312C12.4453 11.8225 12.6735 12.0586 12.955 12.0586H14.0425C14.324 12.0586 14.5522 11.8225 14.5522 11.5312C14.5522 11.24 14.324 11.0039 14.0425 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 13.2539H12.955C12.6735 13.2539 12.4453 13.49 12.4453 13.7812C12.4453 14.0725 12.6735 14.3086 12.955 14.3086H14.0425C14.324 14.3086 14.5522 14.0725 14.5522 13.7812C14.5522 13.49 14.324 13.2539 14.0425 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M16.319 2.28516H15.0956V1.40625C15.0956 1.11502 14.8674 0.878906 14.5859 0.878906C14.3044 0.878906 14.0762 1.11502 14.0762 1.40625V2.28516H9.65845V1.40625C9.65845 1.11502 9.43023 0.878906 9.14872 0.878906C8.86721 0.878906 8.63898 1.11502 8.63898 1.40625V2.28516H4.22127V1.40625C4.22127 1.11502 3.99304 0.878906 3.71153 0.878906C3.43002 0.878906 3.20179 1.11502 3.20179 1.40625V2.28516H1.97843C1.13522 2.28516 0.449219 2.99486 0.449219 3.86719V15.5391C0.449219 16.4114 1.13522 17.1211 1.97843 17.1211H16.319C17.1622 17.1211 17.8482 16.4114 17.8482 15.5391C17.8482 15.1987 17.8482 4.16338 17.8482 3.86719C17.8482 2.99486 17.1622 2.28516 16.319 2.28516ZM1.46869 3.86719C1.46869 3.57641 1.69736 3.33984 1.97843 3.33984H3.20179V4.21875C3.20179 4.50998 3.43002 4.74609 3.71153 4.74609C3.99304 4.74609 4.22127 4.50998 4.22127 4.21875V3.33984H8.63898V4.21875C8.63898 4.50998 8.86721 4.74609 9.14872 4.74609C9.43023 4.74609 9.65845 4.50998 9.65845 4.21875V3.33984H14.0762V4.21875C14.0762 4.50998 14.3044 4.74609 14.5859 4.74609C14.8674 4.74609 15.0956 4.50998 15.0956 4.21875V3.33984H16.319C16.6001 3.33984 16.8287 3.57641 16.8287 3.86719V5.94141H1.46869V3.86719ZM16.319 16.0664H1.97843C1.69736 16.0664 1.46869 15.8298 1.46869 15.5391V6.99609H16.8287V15.5391C16.8287 15.8298 16.6001 16.0664 16.319 16.0664Z"
                                                                                fill="#181818"/>
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0">
                                                                                <rect width="17.399"
                                                                                      height="18"
                                                                                      fill="white"
                                                                                      transform="translate(0.449219)"/>
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </Button>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">Doğum yeri *</span>
                                                            <Form.Control type="text"
                                                                          placeholder="Vətəndaşlığı olduğu ölkəni daxil edin"
                                                                          value={countryBirth}
                                                                          onChange={(e) => setCountryBirth(e.target.value)}/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span
                                                                className="input-title">Vətəndaşlığı olduğu ölkə *</span>
                                                            <Form.Control type="text"
                                                                          placeholder="Vətəndaşlığı olduğu ölkəni daxil edin"
                                                                          value={countryCitizen}
                                                                          onChange={(e) => setCountryCitizen(e.target.value)}/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">Cinsi *</span>
                                                            <Select
                                                                placeholder="Cinsini seçin"
                                                                value={selectedGender}
                                                                onChange={setSelectedGender}
                                                                options={genderOptions}
                                                                styles={customStyles}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">Qan qrupu</span>
                                                            <Select
                                                                placeholder="Qan qrupunu seçin"
                                                                value={selectedBloodType}
                                                                onChange={setSelectedBloodType}
                                                                options={bloodTypeOptions}
                                                                styles={customStyles}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">Vəsiqə və ya müvəqqəti yaşamaq icazəsi</span>
                                                            <Form.Label>
                                                                <Form.Control type="text"
                                                                              placeholder="Vəsiqə və ya müvəqqəti yaşamaq icazəsini daxil edin"
                                                                              value={livePermission}
                                                                              onChange={(e) => setLivePermission(e.target.value)}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span
                                                                className="input-title">Şəxsiy. vəs. verən orqan *</span>
                                                            <Form.Label>
                                                                <Form.Control type="text"
                                                                              placeholder="Şəxsiy. vəs. verən orqanı daxil edin"
                                                                              value={idCardOrganization}
                                                                              onChange={(e) => setIdCardOrganization(e.target.value)}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="block-inn">
                                                <div className="block-title">
                                                    Xarici pasport
                                                </div>
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">Seriya və nömrə *</span>
                                                            <InputGroup>
                                                                <Form.Control className="input-add" placeholder="AZE"
                                                                              value={passportSerial}
                                                                              onChange={(e => setPassportSerial(e.target.value))}/>
                                                                <Form.Control placeholder="Seriya və nömrəni daxil edin"
                                                                              value={passportNumber}
                                                                              onChange={(e => setPassportNumber(e.target.value))}/>
                                                            </InputGroup>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">Doğum tarixi *</span>
                                                            <Form.Label className="relative m-0">
                                                                <DatePicker selected={startPassportDate}
                                                                            dateFormat="dd-MM-yyyy"
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                            onChange={(date) => setStartPassportDate(date)}/>
                                                                <Button className="btn-transparent">
                                                                    <svg width="18" height="18"
                                                                         viewBox="0 0 18 18" fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <g opacity="0.8"
                                                                           clipPath="url(#clip0)">
                                                                            <path
                                                                                d="M5.34327 8.75391H4.25583C3.97432 8.75391 3.74609 8.99002 3.74609 9.28125C3.74609 9.57248 3.97432 9.80859 4.25583 9.80859H5.34327C5.62478 9.80859 5.853 9.57248 5.853 9.28125C5.853 8.99002 5.62478 8.75391 5.34327 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M5.34327 11.0039H4.25583C3.97432 11.0039 3.74609 11.24 3.74609 11.5312C3.74609 11.8225 3.97432 12.0586 4.25583 12.0586H5.34327C5.62478 12.0586 5.853 11.8225 5.853 11.5312C5.853 11.24 5.62478 11.0039 5.34327 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M5.34327 13.2539H4.25583C3.97432 13.2539 3.74609 13.49 3.74609 13.7812C3.74609 14.0725 3.97432 14.3086 4.25583 14.3086H5.34327C5.62478 14.3086 5.853 14.0725 5.853 13.7812C5.853 13.49 5.62478 13.2539 5.34327 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 8.75391H8.60349C8.32198 8.75391 8.09375 8.99002 8.09375 9.28125C8.09375 9.57248 8.32198 9.80859 8.60349 9.80859H9.69092C9.97243 9.80859 10.2007 9.57248 10.2007 9.28125C10.2007 8.99002 9.97243 8.75391 9.69092 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 11.0039H8.60349C8.32198 11.0039 8.09375 11.24 8.09375 11.5312C8.09375 11.8225 8.32198 12.0586 8.60349 12.0586H9.69092C9.97243 12.0586 10.2007 11.8225 10.2007 11.5312C10.2007 11.24 9.97243 11.0039 9.69092 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 13.2539H8.60349C8.32198 13.2539 8.09375 13.49 8.09375 13.7812C8.09375 14.0725 8.32198 14.3086 8.60349 14.3086H9.69092C9.97243 14.3086 10.2007 14.0725 10.2007 13.7812C10.2007 13.49 9.97243 13.2539 9.69092 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 8.75391H12.955C12.6735 8.75391 12.4453 8.99002 12.4453 9.28125C12.4453 9.57248 12.6735 9.80859 12.955 9.80859H14.0425C14.324 9.80859 14.5522 9.57248 14.5522 9.28125C14.5522 8.99002 14.324 8.75391 14.0425 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 11.0039H12.955C12.6735 11.0039 12.4453 11.24 12.4453 11.5312C12.4453 11.8225 12.6735 12.0586 12.955 12.0586H14.0425C14.324 12.0586 14.5522 11.8225 14.5522 11.5312C14.5522 11.24 14.324 11.0039 14.0425 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 13.2539H12.955C12.6735 13.2539 12.4453 13.49 12.4453 13.7812C12.4453 14.0725 12.6735 14.3086 12.955 14.3086H14.0425C14.324 14.3086 14.5522 14.0725 14.5522 13.7812C14.5522 13.49 14.324 13.2539 14.0425 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M16.319 2.28516H15.0956V1.40625C15.0956 1.11502 14.8674 0.878906 14.5859 0.878906C14.3044 0.878906 14.0762 1.11502 14.0762 1.40625V2.28516H9.65845V1.40625C9.65845 1.11502 9.43023 0.878906 9.14872 0.878906C8.86721 0.878906 8.63898 1.11502 8.63898 1.40625V2.28516H4.22127V1.40625C4.22127 1.11502 3.99304 0.878906 3.71153 0.878906C3.43002 0.878906 3.20179 1.11502 3.20179 1.40625V2.28516H1.97843C1.13522 2.28516 0.449219 2.99486 0.449219 3.86719V15.5391C0.449219 16.4114 1.13522 17.1211 1.97843 17.1211H16.319C17.1622 17.1211 17.8482 16.4114 17.8482 15.5391C17.8482 15.1987 17.8482 4.16338 17.8482 3.86719C17.8482 2.99486 17.1622 2.28516 16.319 2.28516ZM1.46869 3.86719C1.46869 3.57641 1.69736 3.33984 1.97843 3.33984H3.20179V4.21875C3.20179 4.50998 3.43002 4.74609 3.71153 4.74609C3.99304 4.74609 4.22127 4.50998 4.22127 4.21875V3.33984H8.63898V4.21875C8.63898 4.50998 8.86721 4.74609 9.14872 4.74609C9.43023 4.74609 9.65845 4.50998 9.65845 4.21875V3.33984H14.0762V4.21875C14.0762 4.50998 14.3044 4.74609 14.5859 4.74609C14.8674 4.74609 15.0956 4.50998 15.0956 4.21875V3.33984H16.319C16.6001 3.33984 16.8287 3.57641 16.8287 3.86719V5.94141H1.46869V3.86719ZM16.319 16.0664H1.97843C1.69736 16.0664 1.46869 15.8298 1.46869 15.5391V6.99609H16.8287V15.5391C16.8287 15.8298 16.6001 16.0664 16.319 16.0664Z"
                                                                                fill="#181818"/>
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0">
                                                                                <rect width="17.399"
                                                                                      height="18"
                                                                                      fill="white"
                                                                                      transform="translate(0.449219)"/>
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </Button>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">Doğum tarixi *</span>
                                                            <Form.Label className="relative m-0">
                                                                <DatePicker selected={expiredPassportDate}
                                                                            dateFormat="dd-MM-yyyy"
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                            onChange={(date) => setExpiredPassportDate(date)}/>
                                                                <Button className="btn-transparent">
                                                                    <svg width="18" height="18"
                                                                         viewBox="0 0 18 18" fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <g opacity="0.8"
                                                                           clipPath="url(#clip0)">
                                                                            <path
                                                                                d="M5.34327 8.75391H4.25583C3.97432 8.75391 3.74609 8.99002 3.74609 9.28125C3.74609 9.57248 3.97432 9.80859 4.25583 9.80859H5.34327C5.62478 9.80859 5.853 9.57248 5.853 9.28125C5.853 8.99002 5.62478 8.75391 5.34327 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M5.34327 11.0039H4.25583C3.97432 11.0039 3.74609 11.24 3.74609 11.5312C3.74609 11.8225 3.97432 12.0586 4.25583 12.0586H5.34327C5.62478 12.0586 5.853 11.8225 5.853 11.5312C5.853 11.24 5.62478 11.0039 5.34327 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M5.34327 13.2539H4.25583C3.97432 13.2539 3.74609 13.49 3.74609 13.7812C3.74609 14.0725 3.97432 14.3086 4.25583 14.3086H5.34327C5.62478 14.3086 5.853 14.0725 5.853 13.7812C5.853 13.49 5.62478 13.2539 5.34327 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 8.75391H8.60349C8.32198 8.75391 8.09375 8.99002 8.09375 9.28125C8.09375 9.57248 8.32198 9.80859 8.60349 9.80859H9.69092C9.97243 9.80859 10.2007 9.57248 10.2007 9.28125C10.2007 8.99002 9.97243 8.75391 9.69092 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 11.0039H8.60349C8.32198 11.0039 8.09375 11.24 8.09375 11.5312C8.09375 11.8225 8.32198 12.0586 8.60349 12.0586H9.69092C9.97243 12.0586 10.2007 11.8225 10.2007 11.5312C10.2007 11.24 9.97243 11.0039 9.69092 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 13.2539H8.60349C8.32198 13.2539 8.09375 13.49 8.09375 13.7812C8.09375 14.0725 8.32198 14.3086 8.60349 14.3086H9.69092C9.97243 14.3086 10.2007 14.0725 10.2007 13.7812C10.2007 13.49 9.97243 13.2539 9.69092 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 8.75391H12.955C12.6735 8.75391 12.4453 8.99002 12.4453 9.28125C12.4453 9.57248 12.6735 9.80859 12.955 9.80859H14.0425C14.324 9.80859 14.5522 9.57248 14.5522 9.28125C14.5522 8.99002 14.324 8.75391 14.0425 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 11.0039H12.955C12.6735 11.0039 12.4453 11.24 12.4453 11.5312C12.4453 11.8225 12.6735 12.0586 12.955 12.0586H14.0425C14.324 12.0586 14.5522 11.8225 14.5522 11.5312C14.5522 11.24 14.324 11.0039 14.0425 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 13.2539H12.955C12.6735 13.2539 12.4453 13.49 12.4453 13.7812C12.4453 14.0725 12.6735 14.3086 12.955 14.3086H14.0425C14.324 14.3086 14.5522 14.0725 14.5522 13.7812C14.5522 13.49 14.324 13.2539 14.0425 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M16.319 2.28516H15.0956V1.40625C15.0956 1.11502 14.8674 0.878906 14.5859 0.878906C14.3044 0.878906 14.0762 1.11502 14.0762 1.40625V2.28516H9.65845V1.40625C9.65845 1.11502 9.43023 0.878906 9.14872 0.878906C8.86721 0.878906 8.63898 1.11502 8.63898 1.40625V2.28516H4.22127V1.40625C4.22127 1.11502 3.99304 0.878906 3.71153 0.878906C3.43002 0.878906 3.20179 1.11502 3.20179 1.40625V2.28516H1.97843C1.13522 2.28516 0.449219 2.99486 0.449219 3.86719V15.5391C0.449219 16.4114 1.13522 17.1211 1.97843 17.1211H16.319C17.1622 17.1211 17.8482 16.4114 17.8482 15.5391C17.8482 15.1987 17.8482 4.16338 17.8482 3.86719C17.8482 2.99486 17.1622 2.28516 16.319 2.28516ZM1.46869 3.86719C1.46869 3.57641 1.69736 3.33984 1.97843 3.33984H3.20179V4.21875C3.20179 4.50998 3.43002 4.74609 3.71153 4.74609C3.99304 4.74609 4.22127 4.50998 4.22127 4.21875V3.33984H8.63898V4.21875C8.63898 4.50998 8.86721 4.74609 9.14872 4.74609C9.43023 4.74609 9.65845 4.50998 9.65845 4.21875V3.33984H14.0762V4.21875C14.0762 4.50998 14.3044 4.74609 14.5859 4.74609C14.8674 4.74609 15.0956 4.50998 15.0956 4.21875V3.33984H16.319C16.6001 3.33984 16.8287 3.57641 16.8287 3.86719V5.94141H1.46869V3.86719ZM16.319 16.0664H1.97843C1.69736 16.0664 1.46869 15.8298 1.46869 15.5391V6.99609H16.8287V15.5391C16.8287 15.8298 16.6001 16.0664 16.319 16.0664Z"
                                                                                fill="#181818"/>
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0">
                                                                                <rect width="17.399"
                                                                                      height="18"
                                                                                      fill="white"
                                                                                      transform="translate(0.449219)"/>
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </Button>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>

                                                </Row>
                                            </div>
                                            <div className="block-inn">
                                                <div className="block-title">
                                                    Ünvan
                                                </div>
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">Ölkə *</span>
                                                            <Select
                                                                placeholder="Ölkə seçin"
                                                                value={selectedCountry}
                                                                onChange={(val) => {
                                                                    setSelectedCountry(val);
                                                                }}
                                                                options={country}
                                                                getOptionLabel={(option) => (option.key)}
                                                                styles={customStyles}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">Şəhər *</span>
                                                            <Select
                                                                placeholder="Şəhər seçin"
                                                                value={selectedCity}
                                                                onChange={(val) => {
                                                                    setSelectedCity(val);
                                                                }}
                                                                options={city}
                                                                getOptionLabel={(option) => (option.key)}
                                                                styles={customStyles}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">Rayon *</span>
                                                            <Select
                                                                placeholder="Rayon seçin"
                                                                value={selectedRegion}
                                                                onChange={(val) => {
                                                                    setSelectedRegion(val);
                                                                }}
                                                                options={region}
                                                                getOptionLabel={(option) => (option.key)}
                                                                styles={customStyles}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">Qəsəbə *</span>
                                                            <Form.Label>
                                                                <Form.Control
                                                                    placeholder="Ölkəni daxil edin"
                                                                    value={settlement}
                                                                    onChange={(e => setSettlement(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">Küçə *</span>
                                                            <Form.Label>
                                                                <Form.Control
                                                                    placeholder="Küçə daxil edin"
                                                                    value={street}
                                                                    onChange={(e => setStreet(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">Məhəllə *</span>
                                                            <Form.Label>
                                                                <Form.Control
                                                                    placeholder="Məhəllə daxil edin"
                                                                    value={block}
                                                                    onChange={(e => setBlock(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Group>
                                                            <span className="input-title">Mənzil *</span>
                                                            <Form.Label>
                                                                <Form.Control
                                                                    placeholder="Mənzil daxil edin"
                                                                    value={apartment}
                                                                    onChange={(e => setApartment(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Group>
                                                            <span className="input-title">Ev *</span>
                                                            <Form.Label>
                                                                <Form.Control
                                                                    placeholder="Ev daxil edin"
                                                                    value={home}
                                                                    onChange={(e => setHome(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className="block-inn">
                                                <div className="block-title">
                                                    Əlaqə vasitələri
                                                </div>
                                                <Row>
                                                    <Col xs={6}>
                                                        <Form.Group>
                                                            <span className="input-title">Ev nömrəsi *</span>
                                                            <Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Ev nömrəsi daxil edin"
                                                                    value={phoneNumber}
                                                                    onChange={(e => setPhoneNumber(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Group>
                                                            <span className="input-title">Mobil nömrəsi 1 *</span>
                                                            <Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Mobil nömrəsi daxil edin"
                                                                    value={mobileNumber1}
                                                                    onChange={(e => setMobileNumber1(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Group>
                                                            <span className="input-title">Mobil nömrəsi 2 *</span>
                                                            <Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Mobil nömrəsi daxil edin"
                                                                    value={mobileNumber2}
                                                                    onChange={(e => setMobileNumber2(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Group>
                                                            <span className="input-title">İş nömrəsi  *</span>
                                                            <Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="İş nömrəsi daxil edin"
                                                                    value={businessPhone}
                                                                    onChange={(e => setBusinessPhone(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">İş nömrəsi ( daxili) *</span>
                                                            <Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="İş nömrəsi daxil edin"
                                                                    value={businessInternalPhone}
                                                                    onChange={(e => setBusinessInternalPhone(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">E-mail ünvanı (şəxsi) *</span>
                                                            <Form.Label>
                                                                <Form.Control
                                                                    placeholder="E-mail ünvanı edin"
                                                                    value={email}
                                                                    onChange={(e => setEmail(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">E-mail ünvanı (iş) *</span>
                                                            <Form.Label>
                                                                <Form.Control
                                                                    placeholder="E-mail ünvanı edin"
                                                                    value={emailBusiness}
                                                                    onChange={(e => setEmailBusiness(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="block-inn">
                                                <div className="block-title">
                                                    Ailə tərkibi haqqında
                                                </div>
                                                <div className="addition-content">
                                                    {
                                                        familyMemberArr.map((item, index) =>
                                                            <div key={uid(item, index)} className={index == 0 ? '' : 'add-item'}>
                                                                {
                                                                    index == 0 ? null :
                                                                        <div className="add-item-top">
                                                                            <p className="m-0">Ailə
                                                                                tərkibi {index + 1}</p>
                                                                            <Button
                                                                                className="btn-transparent btn-remove flex-center"
                                                                                onClick={() => {
                                                                                    familyMemberArr.splice(index, 1);
                                                                                    setFamilyMemberArr([...familyMemberArr], familyMemberArr)
                                                                                }}>
                                                                                <svg width="14" height="14"
                                                                                     viewBox="0 0 14 14" fill="none"
                                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                                    <path
                                                                                        d="M11.1665 2.69336L10.2739 12.8645H3.7302L2.8378 2.69336L1.70703 2.79248L2.61572 13.1481C2.66354 13.6254 3.07769 13.9997 3.5588 13.9997H10.4453C10.9262 13.9997 11.3405 13.6256 11.3892 13.1413L12.2973 2.79248L11.1665 2.69336Z"
                                                                                        fill="#CF3131"/>
                                                                                    <path
                                                                                        d="M9.08077 0H4.91861C4.397 0 3.97266 0.424348 3.97266 0.945957V2.74326H5.10778V1.13512H8.89155V2.74323H10.0267V0.94593C10.0267 0.424348 9.60238 0 9.08077 0Z"
                                                                                        fill="#CF3131"/>
                                                                                    <path
                                                                                        d="M13.0507 2.17578H0.942574C0.629078 2.17578 0.375 2.42986 0.375 2.74336C0.375 3.05685 0.629078 3.31093 0.942574 3.31093H13.0507C13.3642 3.31093 13.6183 3.05685 13.6183 2.74336C13.6183 2.42986 13.3642 2.17578 13.0507 2.17578Z"
                                                                                        fill="#CF3131"/>
                                                                                </svg>
                                                                                <span>Sil</span>
                                                                            </Button>
                                                                        </div>
                                                                }
                                                                <Row>
                                                                    <Col xs={6}>
                                                                        <Form.Group>
                                                                        <span
                                                                            className="input-title">Ailə üzvü  *</span>
                                                                            <Form.Label>
                                                                                <Select
                                                                                    placeholder="Ailə üzvü daxil edin"
                                                                                    onChange={(val) => {
                                                                                        familyMemberArr[index].relationType = val.value;
                                                                                        setFamilyMemberArr([...familyMemberArr], familyMemberArr)
                                                                                    }}
                                                                                    options={relationTypeOptions}
                                                                                    getOptionLabel={(option) => (option.label)}
                                                                                    styles={customStyles}
                                                                                />
                                                                            </Form.Label>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col xs={6}>
                                                                        <Form.Group>
                                                                            <span className="input-title">Soyadı, adı, ata adı</span>
                                                                            <Form.Label>
                                                                                <Form.Control
                                                                                    placeholder="Soyadı, adı, ata adı daxil edin"
                                                                                    //value={familyMemberFullName}
                                                                                    onChange={(e) => {
                                                                                        //setFamilyMemberFullName(e.target.value);
                                                                                        familyMemberArr[index].fullName = e.target.value;
                                                                                        setFamilyMemberArr([...familyMemberArr], familyMemberArr)
                                                                                    }}/>
                                                                            </Form.Label>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col xs={6}>
                                                                        <Form.Group>
                                                                        <span
                                                                            className="input-title">Doğum tarixi</span>
                                                                            <Form.Label className="relative m-0">
                                                                                <DatePicker
                                                                                    selected={familyMemberBirthDate}
                                                                                    dateFormat="dd-MM-yyyy"
                                                                                    showMonthDropdown
                                                                                    showYearDropdown
                                                                                    dropdownMode="select"
                                                                                    onChange={(date) => {
                                                                                        setFamilyMemberBirthDate(date);
                                                                                        familyMemberArr[index].birthday = moment(date).format("DD-MM-YYYY");
                                                                                        setFamilyMemberArr([...familyMemberArr], familyMemberArr)
                                                                                    }}/>
                                                                                <Button className="btn-transparent">
                                                                                    <svg width="18" height="18"
                                                                                         viewBox="0 0 18 18" fill="none"
                                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                                        <g opacity="0.8"
                                                                                           clipPath="url(#clip0)">
                                                                                            <path
                                                                                                d="M5.34327 8.75391H4.25583C3.97432 8.75391 3.74609 8.99002 3.74609 9.28125C3.74609 9.57248 3.97432 9.80859 4.25583 9.80859H5.34327C5.62478 9.80859 5.853 9.57248 5.853 9.28125C5.853 8.99002 5.62478 8.75391 5.34327 8.75391Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M5.34327 11.0039H4.25583C3.97432 11.0039 3.74609 11.24 3.74609 11.5312C3.74609 11.8225 3.97432 12.0586 4.25583 12.0586H5.34327C5.62478 12.0586 5.853 11.8225 5.853 11.5312C5.853 11.24 5.62478 11.0039 5.34327 11.0039Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M5.34327 13.2539H4.25583C3.97432 13.2539 3.74609 13.49 3.74609 13.7812C3.74609 14.0725 3.97432 14.3086 4.25583 14.3086H5.34327C5.62478 14.3086 5.853 14.0725 5.853 13.7812C5.853 13.49 5.62478 13.2539 5.34327 13.2539Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M9.69092 8.75391H8.60349C8.32198 8.75391 8.09375 8.99002 8.09375 9.28125C8.09375 9.57248 8.32198 9.80859 8.60349 9.80859H9.69092C9.97243 9.80859 10.2007 9.57248 10.2007 9.28125C10.2007 8.99002 9.97243 8.75391 9.69092 8.75391Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M9.69092 11.0039H8.60349C8.32198 11.0039 8.09375 11.24 8.09375 11.5312C8.09375 11.8225 8.32198 12.0586 8.60349 12.0586H9.69092C9.97243 12.0586 10.2007 11.8225 10.2007 11.5312C10.2007 11.24 9.97243 11.0039 9.69092 11.0039Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M9.69092 13.2539H8.60349C8.32198 13.2539 8.09375 13.49 8.09375 13.7812C8.09375 14.0725 8.32198 14.3086 8.60349 14.3086H9.69092C9.97243 14.3086 10.2007 14.0725 10.2007 13.7812C10.2007 13.49 9.97243 13.2539 9.69092 13.2539Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M14.0425 8.75391H12.955C12.6735 8.75391 12.4453 8.99002 12.4453 9.28125C12.4453 9.57248 12.6735 9.80859 12.955 9.80859H14.0425C14.324 9.80859 14.5522 9.57248 14.5522 9.28125C14.5522 8.99002 14.324 8.75391 14.0425 8.75391Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M14.0425 11.0039H12.955C12.6735 11.0039 12.4453 11.24 12.4453 11.5312C12.4453 11.8225 12.6735 12.0586 12.955 12.0586H14.0425C14.324 12.0586 14.5522 11.8225 14.5522 11.5312C14.5522 11.24 14.324 11.0039 14.0425 11.0039Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M14.0425 13.2539H12.955C12.6735 13.2539 12.4453 13.49 12.4453 13.7812C12.4453 14.0725 12.6735 14.3086 12.955 14.3086H14.0425C14.324 14.3086 14.5522 14.0725 14.5522 13.7812C14.5522 13.49 14.324 13.2539 14.0425 13.2539Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M16.319 2.28516H15.0956V1.40625C15.0956 1.11502 14.8674 0.878906 14.5859 0.878906C14.3044 0.878906 14.0762 1.11502 14.0762 1.40625V2.28516H9.65845V1.40625C9.65845 1.11502 9.43023 0.878906 9.14872 0.878906C8.86721 0.878906 8.63898 1.11502 8.63898 1.40625V2.28516H4.22127V1.40625C4.22127 1.11502 3.99304 0.878906 3.71153 0.878906C3.43002 0.878906 3.20179 1.11502 3.20179 1.40625V2.28516H1.97843C1.13522 2.28516 0.449219 2.99486 0.449219 3.86719V15.5391C0.449219 16.4114 1.13522 17.1211 1.97843 17.1211H16.319C17.1622 17.1211 17.8482 16.4114 17.8482 15.5391C17.8482 15.1987 17.8482 4.16338 17.8482 3.86719C17.8482 2.99486 17.1622 2.28516 16.319 2.28516ZM1.46869 3.86719C1.46869 3.57641 1.69736 3.33984 1.97843 3.33984H3.20179V4.21875C3.20179 4.50998 3.43002 4.74609 3.71153 4.74609C3.99304 4.74609 4.22127 4.50998 4.22127 4.21875V3.33984H8.63898V4.21875C8.63898 4.50998 8.86721 4.74609 9.14872 4.74609C9.43023 4.74609 9.65845 4.50998 9.65845 4.21875V3.33984H14.0762V4.21875C14.0762 4.50998 14.3044 4.74609 14.5859 4.74609C14.8674 4.74609 15.0956 4.50998 15.0956 4.21875V3.33984H16.319C16.6001 3.33984 16.8287 3.57641 16.8287 3.86719V5.94141H1.46869V3.86719ZM16.319 16.0664H1.97843C1.69736 16.0664 1.46869 15.8298 1.46869 15.5391V6.99609H16.8287V15.5391C16.8287 15.8298 16.6001 16.0664 16.319 16.0664Z"
                                                                                                fill="#181818"/>
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0">
                                                                                                <rect width="17.399"
                                                                                                      height="18"
                                                                                                      fill="white"
                                                                                                      transform="translate(0.449219)"/>
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg>
                                                                                </Button>
                                                                            </Form.Label>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col xs={6}>
                                                                        <Form.Group>
                                                                            <span
                                                                                className="input-title">Doğum yeri</span>
                                                                            <Form.Label>
                                                                                <Form.Control
                                                                                    placeholder="Doğum yeri daxil edin"
                                                                                    //value={familyMemberBirthPlace}
                                                                                    onChange={(e) => {
                                                                                        //setFamilyMemberBirthPlace(e.target.value);
                                                                                        familyMemberArr[index].birthplace = e.target.value;
                                                                                        setFamilyMemberArr([...familyMemberArr], familyMemberArr)
                                                                                    }}/>
                                                                            </Form.Label>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col xs={4}>
                                                                        <Form.Group>
                                                                            <span className="input-title">İş yeri</span>
                                                                            <Form.Label>
                                                                                <Form.Control
                                                                                    placeholder="Yeri daxil edin"
                                                                                    //value={familyMemberWorkPlace}
                                                                                    onChange={(e) => {
                                                                                        //setFamilyMemberWorkPlace(e.target.value);
                                                                                        familyMemberArr[index].workPlace = e.target.value;
                                                                                        setFamilyMemberArr([...familyMemberArr], familyMemberArr)
                                                                                    }}/>
                                                                            </Form.Label>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col xs={4}>
                                                                        <Form.Group>
                                                                            <span
                                                                                className="input-title">Vəzifəsi</span>
                                                                            <Form.Label>
                                                                                <Form.Control
                                                                                    placeholder="Vəzifə daxil edin"
                                                                                    //value={familyMemberProfession}
                                                                                    onChange={(e) => {
                                                                                        //setFamilyMemberProfession(e.target.value);
                                                                                        familyMemberArr[index].position = e.target.value;
                                                                                        setFamilyMemberArr([...familyMemberArr], familyMemberArr)
                                                                                    }}/>
                                                                            </Form.Label>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col xs={4}>
                                                                        <Form.Group>
                                                                            <span className="input-title">Yaşayış</span>
                                                                            <Form.Label>
                                                                                <Form.Control
                                                                                    placeholder="Yaşayış daxil edin"
                                                                                    //value={familyMemberAddress}
                                                                                    onChange={(e) => {
                                                                                        // setFamilyMemberAddress(e.target.value);
                                                                                        familyMemberArr[index].address = e.target.value;
                                                                                        setFamilyMemberArr([...familyMemberArr], familyMemberArr)
                                                                                    }}/>
                                                                            </Form.Label>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )
                                                    }
                                                    <div className="flex-end">
                                                        <Button className="btn-main" onClick={() => addFamilyMember()}>
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M15.8346 10.8337H10.8346V15.8337H9.16797V10.8337H4.16797V9.16699H9.16797V4.16699H10.8346V9.16699H15.8346V10.8337Z"
                                                                    fill="white"/>
                                                            </svg>
                                                            Əlavə et
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-vertical-center">
                                            <Button className="btn-effect" onClick={() => sendData()}>
                                                Davam et
                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            </Tab>
                            <Tab eventKey="company" title="Şirkət barədə" disabled={key !== "company"}>
                                <div className="block">
                                    <Form className="form-list">
                                        <div className="add-block">
                                            <div className="block-title">
                                                Limana qədər əmək fəaliyyəti barədə məlumatlar
                                            </div>
                                            <div className="block-inn">
                                                <Row>
                                                    <Col xs={6}>
                                                        <Form.Group>
                                                            <span className="input-title">İşçinin işlədiyi şirkət</span>
                                                            <Form.Label>
                                                                <Form.Control placeholder="Şirkət daxil et"
                                                                              value={company}
                                                                              onChange={(e => setCompany(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Group>
                                                            <span className="input-title">Struktur bölmə</span>
                                                            <Form.Label>
                                                                <Form.Control placeholder="Struktur bölmə daxil et"
                                                                              value={section}
                                                                              onChange={(e => setSection(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">Alt struktur bölmə</span>
                                                            <Form.Label>
                                                                <Form.Control placeholder="Alt struktur  bölmə daxil et"
                                                                              value={subSection}
                                                                              onChange={(e => setSubSection(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">İşçinin işlədiyi vəzifə</span>
                                                            <Form.Label>
                                                                <Form.Control placeholder="Struktur bölmə daxil et"
                                                                              value={employeePosition}
                                                                              onChange={(e => setEmployeePosition(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">İşə qəbul tarixi *</span>
                                                            <Form.Label className="relative m-0">
                                                                <DatePicker selected={startJobDate}
                                                                            dateFormat="dd-MM-yyyy"
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                            onChange={(date) => setStartJobDate(date)}/>
                                                                <Button className="btn-transparent">
                                                                    <svg width="18" height="18"
                                                                         viewBox="0 0 18 18" fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <g opacity="0.8"
                                                                           clipPath="url(#clip0)">
                                                                            <path
                                                                                d="M5.34327 8.75391H4.25583C3.97432 8.75391 3.74609 8.99002 3.74609 9.28125C3.74609 9.57248 3.97432 9.80859 4.25583 9.80859H5.34327C5.62478 9.80859 5.853 9.57248 5.853 9.28125C5.853 8.99002 5.62478 8.75391 5.34327 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M5.34327 11.0039H4.25583C3.97432 11.0039 3.74609 11.24 3.74609 11.5312C3.74609 11.8225 3.97432 12.0586 4.25583 12.0586H5.34327C5.62478 12.0586 5.853 11.8225 5.853 11.5312C5.853 11.24 5.62478 11.0039 5.34327 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M5.34327 13.2539H4.25583C3.97432 13.2539 3.74609 13.49 3.74609 13.7812C3.74609 14.0725 3.97432 14.3086 4.25583 14.3086H5.34327C5.62478 14.3086 5.853 14.0725 5.853 13.7812C5.853 13.49 5.62478 13.2539 5.34327 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 8.75391H8.60349C8.32198 8.75391 8.09375 8.99002 8.09375 9.28125C8.09375 9.57248 8.32198 9.80859 8.60349 9.80859H9.69092C9.97243 9.80859 10.2007 9.57248 10.2007 9.28125C10.2007 8.99002 9.97243 8.75391 9.69092 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 11.0039H8.60349C8.32198 11.0039 8.09375 11.24 8.09375 11.5312C8.09375 11.8225 8.32198 12.0586 8.60349 12.0586H9.69092C9.97243 12.0586 10.2007 11.8225 10.2007 11.5312C10.2007 11.24 9.97243 11.0039 9.69092 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 13.2539H8.60349C8.32198 13.2539 8.09375 13.49 8.09375 13.7812C8.09375 14.0725 8.32198 14.3086 8.60349 14.3086H9.69092C9.97243 14.3086 10.2007 14.0725 10.2007 13.7812C10.2007 13.49 9.97243 13.2539 9.69092 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 8.75391H12.955C12.6735 8.75391 12.4453 8.99002 12.4453 9.28125C12.4453 9.57248 12.6735 9.80859 12.955 9.80859H14.0425C14.324 9.80859 14.5522 9.57248 14.5522 9.28125C14.5522 8.99002 14.324 8.75391 14.0425 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 11.0039H12.955C12.6735 11.0039 12.4453 11.24 12.4453 11.5312C12.4453 11.8225 12.6735 12.0586 12.955 12.0586H14.0425C14.324 12.0586 14.5522 11.8225 14.5522 11.5312C14.5522 11.24 14.324 11.0039 14.0425 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 13.2539H12.955C12.6735 13.2539 12.4453 13.49 12.4453 13.7812C12.4453 14.0725 12.6735 14.3086 12.955 14.3086H14.0425C14.324 14.3086 14.5522 14.0725 14.5522 13.7812C14.5522 13.49 14.324 13.2539 14.0425 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M16.319 2.28516H15.0956V1.40625C15.0956 1.11502 14.8674 0.878906 14.5859 0.878906C14.3044 0.878906 14.0762 1.11502 14.0762 1.40625V2.28516H9.65845V1.40625C9.65845 1.11502 9.43023 0.878906 9.14872 0.878906C8.86721 0.878906 8.63898 1.11502 8.63898 1.40625V2.28516H4.22127V1.40625C4.22127 1.11502 3.99304 0.878906 3.71153 0.878906C3.43002 0.878906 3.20179 1.11502 3.20179 1.40625V2.28516H1.97843C1.13522 2.28516 0.449219 2.99486 0.449219 3.86719V15.5391C0.449219 16.4114 1.13522 17.1211 1.97843 17.1211H16.319C17.1622 17.1211 17.8482 16.4114 17.8482 15.5391C17.8482 15.1987 17.8482 4.16338 17.8482 3.86719C17.8482 2.99486 17.1622 2.28516 16.319 2.28516ZM1.46869 3.86719C1.46869 3.57641 1.69736 3.33984 1.97843 3.33984H3.20179V4.21875C3.20179 4.50998 3.43002 4.74609 3.71153 4.74609C3.99304 4.74609 4.22127 4.50998 4.22127 4.21875V3.33984H8.63898V4.21875C8.63898 4.50998 8.86721 4.74609 9.14872 4.74609C9.43023 4.74609 9.65845 4.50998 9.65845 4.21875V3.33984H14.0762V4.21875C14.0762 4.50998 14.3044 4.74609 14.5859 4.74609C14.8674 4.74609 15.0956 4.50998 15.0956 4.21875V3.33984H16.319C16.6001 3.33984 16.8287 3.57641 16.8287 3.86719V5.94141H1.46869V3.86719ZM16.319 16.0664H1.97843C1.69736 16.0664 1.46869 15.8298 1.46869 15.5391V6.99609H16.8287V15.5391C16.8287 15.8298 16.6001 16.0664 16.319 16.0664Z"
                                                                                fill="#181818"/>
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0">
                                                                                <rect width="17.399"
                                                                                      height="18"
                                                                                      fill="white"
                                                                                      transform="translate(0.449219)"/>
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </Button>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Group>
                                                            <span className="input-title">İşdən azad tarixi *</span>
                                                            <Form.Label className="relative m-0">
                                                                <DatePicker selected={endJobDate}
                                                                            dateFormat="dd-MM-yyyy"
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                            onChange={(date) => setEndJobDate(date)}/>
                                                                <Button className="btn-transparent">
                                                                    <svg width="18" height="18"
                                                                         viewBox="0 0 18 18" fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <g opacity="0.8"
                                                                           clipPath="url(#clip0)">
                                                                            <path
                                                                                d="M5.34327 8.75391H4.25583C3.97432 8.75391 3.74609 8.99002 3.74609 9.28125C3.74609 9.57248 3.97432 9.80859 4.25583 9.80859H5.34327C5.62478 9.80859 5.853 9.57248 5.853 9.28125C5.853 8.99002 5.62478 8.75391 5.34327 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M5.34327 11.0039H4.25583C3.97432 11.0039 3.74609 11.24 3.74609 11.5312C3.74609 11.8225 3.97432 12.0586 4.25583 12.0586H5.34327C5.62478 12.0586 5.853 11.8225 5.853 11.5312C5.853 11.24 5.62478 11.0039 5.34327 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M5.34327 13.2539H4.25583C3.97432 13.2539 3.74609 13.49 3.74609 13.7812C3.74609 14.0725 3.97432 14.3086 4.25583 14.3086H5.34327C5.62478 14.3086 5.853 14.0725 5.853 13.7812C5.853 13.49 5.62478 13.2539 5.34327 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 8.75391H8.60349C8.32198 8.75391 8.09375 8.99002 8.09375 9.28125C8.09375 9.57248 8.32198 9.80859 8.60349 9.80859H9.69092C9.97243 9.80859 10.2007 9.57248 10.2007 9.28125C10.2007 8.99002 9.97243 8.75391 9.69092 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 11.0039H8.60349C8.32198 11.0039 8.09375 11.24 8.09375 11.5312C8.09375 11.8225 8.32198 12.0586 8.60349 12.0586H9.69092C9.97243 12.0586 10.2007 11.8225 10.2007 11.5312C10.2007 11.24 9.97243 11.0039 9.69092 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 13.2539H8.60349C8.32198 13.2539 8.09375 13.49 8.09375 13.7812C8.09375 14.0725 8.32198 14.3086 8.60349 14.3086H9.69092C9.97243 14.3086 10.2007 14.0725 10.2007 13.7812C10.2007 13.49 9.97243 13.2539 9.69092 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 8.75391H12.955C12.6735 8.75391 12.4453 8.99002 12.4453 9.28125C12.4453 9.57248 12.6735 9.80859 12.955 9.80859H14.0425C14.324 9.80859 14.5522 9.57248 14.5522 9.28125C14.5522 8.99002 14.324 8.75391 14.0425 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 11.0039H12.955C12.6735 11.0039 12.4453 11.24 12.4453 11.5312C12.4453 11.8225 12.6735 12.0586 12.955 12.0586H14.0425C14.324 12.0586 14.5522 11.8225 14.5522 11.5312C14.5522 11.24 14.324 11.0039 14.0425 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 13.2539H12.955C12.6735 13.2539 12.4453 13.49 12.4453 13.7812C12.4453 14.0725 12.6735 14.3086 12.955 14.3086H14.0425C14.324 14.3086 14.5522 14.0725 14.5522 13.7812C14.5522 13.49 14.324 13.2539 14.0425 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M16.319 2.28516H15.0956V1.40625C15.0956 1.11502 14.8674 0.878906 14.5859 0.878906C14.3044 0.878906 14.0762 1.11502 14.0762 1.40625V2.28516H9.65845V1.40625C9.65845 1.11502 9.43023 0.878906 9.14872 0.878906C8.86721 0.878906 8.63898 1.11502 8.63898 1.40625V2.28516H4.22127V1.40625C4.22127 1.11502 3.99304 0.878906 3.71153 0.878906C3.43002 0.878906 3.20179 1.11502 3.20179 1.40625V2.28516H1.97843C1.13522 2.28516 0.449219 2.99486 0.449219 3.86719V15.5391C0.449219 16.4114 1.13522 17.1211 1.97843 17.1211H16.319C17.1622 17.1211 17.8482 16.4114 17.8482 15.5391C17.8482 15.1987 17.8482 4.16338 17.8482 3.86719C17.8482 2.99486 17.1622 2.28516 16.319 2.28516ZM1.46869 3.86719C1.46869 3.57641 1.69736 3.33984 1.97843 3.33984H3.20179V4.21875C3.20179 4.50998 3.43002 4.74609 3.71153 4.74609C3.99304 4.74609 4.22127 4.50998 4.22127 4.21875V3.33984H8.63898V4.21875C8.63898 4.50998 8.86721 4.74609 9.14872 4.74609C9.43023 4.74609 9.65845 4.50998 9.65845 4.21875V3.33984H14.0762V4.21875C14.0762 4.50998 14.3044 4.74609 14.5859 4.74609C14.8674 4.74609 15.0956 4.50998 15.0956 4.21875V3.33984H16.319C16.6001 3.33984 16.8287 3.57641 16.8287 3.86719V5.94141H1.46869V3.86719ZM16.319 16.0664H1.97843C1.69736 16.0664 1.46869 15.8298 1.46869 15.5391V6.99609H16.8287V15.5391C16.8287 15.8298 16.6001 16.0664 16.319 16.0664Z"
                                                                                fill="#181818"/>
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0">
                                                                                <rect width="17.399"
                                                                                      height="18"
                                                                                      fill="white"
                                                                                      transform="translate(0.449219)"/>
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </Button>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Group>
                                                            <span className="input-title">İşdən azad olma maddəsi</span>
                                                            <Form.Label>
                                                                <Form.Control
                                                                    placeholder="İşdən azad olma maddəsi  daxil et"
                                                                    value={firedReason}
                                                                    onChange={(e => setFiredReason(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <div className="radio-content">
                                                    <h5>Əsas iş yeridir yoxsa əlavə iş yeri?</h5>
                                                    <div className="flex-start">
                                                        <div className="radio-block">
                                                            <label className="radio-label">
                                                                <input type="radio" name="radio" checked={checked}
                                                                       onChange={(e) => {
                                                                           setChecked(true)
                                                                       }}/>
                                                                <span className="radio-mark"></span>
                                                            </label>
                                                            <span className="radio-title">Əsas iş yeri</span>
                                                        </div>
                                                        <div className="radio-block">
                                                            <label className="radio-label">
                                                                <input type="radio" name="radio" checked={!checked}
                                                                       onChange={(e) => {
                                                                           setChecked(false)
                                                                       }}/>
                                                                <span className="radio-mark"></span>
                                                            </label>
                                                            <span className="radio-title">Əlavə iş yeri</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex-vertical-center">
                                            <Button className="btn-effect" onClick={() => sendDataBusiness()}>
                                                Yadda saxla
                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            </Tab>
                            <Tab eventKey="education" title="Təhsil" disabled={key !== "education"}>
                                <div className="block">
                                    <Form className="form-list">
                                        <div className="add-block">
                                            <div className="block-title">
                                                Elmi dərəcə
                                            </div>
                                            <div className="block-inn">
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title"> Verilmə tarixi</span>
                                                            <Form.Label className="relative m-0">
                                                                <DatePicker selected={startAcademicDegreeDate}
                                                                            dateFormat="dd-MM-yyyy"
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                            onChange={(date) => setStartAcademicDegreeDate(date)}/>
                                                                <Button className="btn-transparent">
                                                                    <svg width="18" height="18"
                                                                         viewBox="0 0 18 18" fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <g opacity="0.8"
                                                                           clipPath="url(#clip0)">
                                                                            <path
                                                                                d="M5.34327 8.75391H4.25583C3.97432 8.75391 3.74609 8.99002 3.74609 9.28125C3.74609 9.57248 3.97432 9.80859 4.25583 9.80859H5.34327C5.62478 9.80859 5.853 9.57248 5.853 9.28125C5.853 8.99002 5.62478 8.75391 5.34327 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M5.34327 11.0039H4.25583C3.97432 11.0039 3.74609 11.24 3.74609 11.5312C3.74609 11.8225 3.97432 12.0586 4.25583 12.0586H5.34327C5.62478 12.0586 5.853 11.8225 5.853 11.5312C5.853 11.24 5.62478 11.0039 5.34327 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M5.34327 13.2539H4.25583C3.97432 13.2539 3.74609 13.49 3.74609 13.7812C3.74609 14.0725 3.97432 14.3086 4.25583 14.3086H5.34327C5.62478 14.3086 5.853 14.0725 5.853 13.7812C5.853 13.49 5.62478 13.2539 5.34327 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 8.75391H8.60349C8.32198 8.75391 8.09375 8.99002 8.09375 9.28125C8.09375 9.57248 8.32198 9.80859 8.60349 9.80859H9.69092C9.97243 9.80859 10.2007 9.57248 10.2007 9.28125C10.2007 8.99002 9.97243 8.75391 9.69092 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 11.0039H8.60349C8.32198 11.0039 8.09375 11.24 8.09375 11.5312C8.09375 11.8225 8.32198 12.0586 8.60349 12.0586H9.69092C9.97243 12.0586 10.2007 11.8225 10.2007 11.5312C10.2007 11.24 9.97243 11.0039 9.69092 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 13.2539H8.60349C8.32198 13.2539 8.09375 13.49 8.09375 13.7812C8.09375 14.0725 8.32198 14.3086 8.60349 14.3086H9.69092C9.97243 14.3086 10.2007 14.0725 10.2007 13.7812C10.2007 13.49 9.97243 13.2539 9.69092 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 8.75391H12.955C12.6735 8.75391 12.4453 8.99002 12.4453 9.28125C12.4453 9.57248 12.6735 9.80859 12.955 9.80859H14.0425C14.324 9.80859 14.5522 9.57248 14.5522 9.28125C14.5522 8.99002 14.324 8.75391 14.0425 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 11.0039H12.955C12.6735 11.0039 12.4453 11.24 12.4453 11.5312C12.4453 11.8225 12.6735 12.0586 12.955 12.0586H14.0425C14.324 12.0586 14.5522 11.8225 14.5522 11.5312C14.5522 11.24 14.324 11.0039 14.0425 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 13.2539H12.955C12.6735 13.2539 12.4453 13.49 12.4453 13.7812C12.4453 14.0725 12.6735 14.3086 12.955 14.3086H14.0425C14.324 14.3086 14.5522 14.0725 14.5522 13.7812C14.5522 13.49 14.324 13.2539 14.0425 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M16.319 2.28516H15.0956V1.40625C15.0956 1.11502 14.8674 0.878906 14.5859 0.878906C14.3044 0.878906 14.0762 1.11502 14.0762 1.40625V2.28516H9.65845V1.40625C9.65845 1.11502 9.43023 0.878906 9.14872 0.878906C8.86721 0.878906 8.63898 1.11502 8.63898 1.40625V2.28516H4.22127V1.40625C4.22127 1.11502 3.99304 0.878906 3.71153 0.878906C3.43002 0.878906 3.20179 1.11502 3.20179 1.40625V2.28516H1.97843C1.13522 2.28516 0.449219 2.99486 0.449219 3.86719V15.5391C0.449219 16.4114 1.13522 17.1211 1.97843 17.1211H16.319C17.1622 17.1211 17.8482 16.4114 17.8482 15.5391C17.8482 15.1987 17.8482 4.16338 17.8482 3.86719C17.8482 2.99486 17.1622 2.28516 16.319 2.28516ZM1.46869 3.86719C1.46869 3.57641 1.69736 3.33984 1.97843 3.33984H3.20179V4.21875C3.20179 4.50998 3.43002 4.74609 3.71153 4.74609C3.99304 4.74609 4.22127 4.50998 4.22127 4.21875V3.33984H8.63898V4.21875C8.63898 4.50998 8.86721 4.74609 9.14872 4.74609C9.43023 4.74609 9.65845 4.50998 9.65845 4.21875V3.33984H14.0762V4.21875C14.0762 4.50998 14.3044 4.74609 14.5859 4.74609C14.8674 4.74609 15.0956 4.50998 15.0956 4.21875V3.33984H16.319C16.6001 3.33984 16.8287 3.57641 16.8287 3.86719V5.94141H1.46869V3.86719ZM16.319 16.0664H1.97843C1.69736 16.0664 1.46869 15.8298 1.46869 15.5391V6.99609H16.8287V15.5391C16.8287 15.8298 16.6001 16.0664 16.319 16.0664Z"
                                                                                fill="#181818"/>
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0">
                                                                                <rect width="17.399"
                                                                                      height="18"
                                                                                      fill="white"
                                                                                      transform="translate(0.449219)"/>
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </Button>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">Sənədin nömrəsi</span>
                                                            <Form.Label>
                                                                <Form.Control placeholder="Sənədin nömrəsi daxil et"
                                                                              value={academicDegreeNumber}
                                                                              onChange={(e => setAcademicDegreeNumber(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">Verən orqan</span>
                                                            <Form.Label>
                                                                <Form.Control placeholder="Verən orqan daxil et"
                                                                              value={academicDegreeOrganization}
                                                                              onChange={(e => setAcademicDegreeOrganization(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div className="add-block">
                                            <div className="block-title">
                                                Təhsil bölməsi
                                            </div>
                                            <div className="block-inn">
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">Təhsil müəssəsinin adı</span>
                                                            <Select
                                                                placeholder="Təhsil müəssəsini seçin"
                                                                value={selectedUniversity}
                                                                onChange={(val) => {
                                                                    setSelectedUniversity(val);
                                                                }}
                                                                options={university}
                                                                getOptionLabel={(option) => (option.key)}
                                                                styles={customStyles}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">Fakültə</span>
                                                            <Form.Label>
                                                                <Form.Control placeholder="Fakültə daxil et"
                                                                              value={faculty}
                                                                              onChange={(e => setFaculty(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">İstiqamət</span>
                                                            <Form.Label>
                                                                <Form.Control placeholder="İstiqamət daxil et"
                                                                              value={direction}
                                                                              onChange={(e => setDirection(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title"> İxtisas</span>
                                                            <Form.Label className="relative m-0">
                                                                <Form.Control placeholder="İxtisas daxil et"
                                                                              value={major}
                                                                              onChange={(e => setMajor(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title"> Daxil olma tarixi</span>
                                                            <Form.Label className="relative m-0">
                                                                <DatePicker selected={startGraduateDate}
                                                                            dateFormat="dd-MM-yyyy"
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                            onChange={(date) => setStartGraduateDate(date)}/>
                                                                <Button className="btn-transparent">
                                                                    <svg width="18" height="18"
                                                                         viewBox="0 0 18 18" fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <g opacity="0.8"
                                                                           clipPath="url(#clip0)">
                                                                            <path
                                                                                d="M5.34327 8.75391H4.25583C3.97432 8.75391 3.74609 8.99002 3.74609 9.28125C3.74609 9.57248 3.97432 9.80859 4.25583 9.80859H5.34327C5.62478 9.80859 5.853 9.57248 5.853 9.28125C5.853 8.99002 5.62478 8.75391 5.34327 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M5.34327 11.0039H4.25583C3.97432 11.0039 3.74609 11.24 3.74609 11.5312C3.74609 11.8225 3.97432 12.0586 4.25583 12.0586H5.34327C5.62478 12.0586 5.853 11.8225 5.853 11.5312C5.853 11.24 5.62478 11.0039 5.34327 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M5.34327 13.2539H4.25583C3.97432 13.2539 3.74609 13.49 3.74609 13.7812C3.74609 14.0725 3.97432 14.3086 4.25583 14.3086H5.34327C5.62478 14.3086 5.853 14.0725 5.853 13.7812C5.853 13.49 5.62478 13.2539 5.34327 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 8.75391H8.60349C8.32198 8.75391 8.09375 8.99002 8.09375 9.28125C8.09375 9.57248 8.32198 9.80859 8.60349 9.80859H9.69092C9.97243 9.80859 10.2007 9.57248 10.2007 9.28125C10.2007 8.99002 9.97243 8.75391 9.69092 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 11.0039H8.60349C8.32198 11.0039 8.09375 11.24 8.09375 11.5312C8.09375 11.8225 8.32198 12.0586 8.60349 12.0586H9.69092C9.97243 12.0586 10.2007 11.8225 10.2007 11.5312C10.2007 11.24 9.97243 11.0039 9.69092 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 13.2539H8.60349C8.32198 13.2539 8.09375 13.49 8.09375 13.7812C8.09375 14.0725 8.32198 14.3086 8.60349 14.3086H9.69092C9.97243 14.3086 10.2007 14.0725 10.2007 13.7812C10.2007 13.49 9.97243 13.2539 9.69092 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 8.75391H12.955C12.6735 8.75391 12.4453 8.99002 12.4453 9.28125C12.4453 9.57248 12.6735 9.80859 12.955 9.80859H14.0425C14.324 9.80859 14.5522 9.57248 14.5522 9.28125C14.5522 8.99002 14.324 8.75391 14.0425 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 11.0039H12.955C12.6735 11.0039 12.4453 11.24 12.4453 11.5312C12.4453 11.8225 12.6735 12.0586 12.955 12.0586H14.0425C14.324 12.0586 14.5522 11.8225 14.5522 11.5312C14.5522 11.24 14.324 11.0039 14.0425 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 13.2539H12.955C12.6735 13.2539 12.4453 13.49 12.4453 13.7812C12.4453 14.0725 12.6735 14.3086 12.955 14.3086H14.0425C14.324 14.3086 14.5522 14.0725 14.5522 13.7812C14.5522 13.49 14.324 13.2539 14.0425 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M16.319 2.28516H15.0956V1.40625C15.0956 1.11502 14.8674 0.878906 14.5859 0.878906C14.3044 0.878906 14.0762 1.11502 14.0762 1.40625V2.28516H9.65845V1.40625C9.65845 1.11502 9.43023 0.878906 9.14872 0.878906C8.86721 0.878906 8.63898 1.11502 8.63898 1.40625V2.28516H4.22127V1.40625C4.22127 1.11502 3.99304 0.878906 3.71153 0.878906C3.43002 0.878906 3.20179 1.11502 3.20179 1.40625V2.28516H1.97843C1.13522 2.28516 0.449219 2.99486 0.449219 3.86719V15.5391C0.449219 16.4114 1.13522 17.1211 1.97843 17.1211H16.319C17.1622 17.1211 17.8482 16.4114 17.8482 15.5391C17.8482 15.1987 17.8482 4.16338 17.8482 3.86719C17.8482 2.99486 17.1622 2.28516 16.319 2.28516ZM1.46869 3.86719C1.46869 3.57641 1.69736 3.33984 1.97843 3.33984H3.20179V4.21875C3.20179 4.50998 3.43002 4.74609 3.71153 4.74609C3.99304 4.74609 4.22127 4.50998 4.22127 4.21875V3.33984H8.63898V4.21875C8.63898 4.50998 8.86721 4.74609 9.14872 4.74609C9.43023 4.74609 9.65845 4.50998 9.65845 4.21875V3.33984H14.0762V4.21875C14.0762 4.50998 14.3044 4.74609 14.5859 4.74609C14.8674 4.74609 15.0956 4.50998 15.0956 4.21875V3.33984H16.319C16.6001 3.33984 16.8287 3.57641 16.8287 3.86719V5.94141H1.46869V3.86719ZM16.319 16.0664H1.97843C1.69736 16.0664 1.46869 15.8298 1.46869 15.5391V6.99609H16.8287V15.5391C16.8287 15.8298 16.6001 16.0664 16.319 16.0664Z"
                                                                                fill="#181818"/>
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0">
                                                                                <rect width="17.399"
                                                                                      height="18"
                                                                                      fill="white"
                                                                                      transform="translate(0.449219)"/>
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </Button>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title"> Bitmə tarixi</span>
                                                            <Form.Label className="relative m-0">
                                                                <DatePicker selected={endGraduateDate}
                                                                            dateFormat="dd-MM-yyyy"
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                            onChange={(date) => setEndGraduateDate(date)}/>
                                                                <Button className="btn-transparent">
                                                                    <svg width="18" height="18"
                                                                         viewBox="0 0 18 18" fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <g opacity="0.8"
                                                                           clipPath="url(#clip0)">
                                                                            <path
                                                                                d="M5.34327 8.75391H4.25583C3.97432 8.75391 3.74609 8.99002 3.74609 9.28125C3.74609 9.57248 3.97432 9.80859 4.25583 9.80859H5.34327C5.62478 9.80859 5.853 9.57248 5.853 9.28125C5.853 8.99002 5.62478 8.75391 5.34327 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M5.34327 11.0039H4.25583C3.97432 11.0039 3.74609 11.24 3.74609 11.5312C3.74609 11.8225 3.97432 12.0586 4.25583 12.0586H5.34327C5.62478 12.0586 5.853 11.8225 5.853 11.5312C5.853 11.24 5.62478 11.0039 5.34327 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M5.34327 13.2539H4.25583C3.97432 13.2539 3.74609 13.49 3.74609 13.7812C3.74609 14.0725 3.97432 14.3086 4.25583 14.3086H5.34327C5.62478 14.3086 5.853 14.0725 5.853 13.7812C5.853 13.49 5.62478 13.2539 5.34327 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 8.75391H8.60349C8.32198 8.75391 8.09375 8.99002 8.09375 9.28125C8.09375 9.57248 8.32198 9.80859 8.60349 9.80859H9.69092C9.97243 9.80859 10.2007 9.57248 10.2007 9.28125C10.2007 8.99002 9.97243 8.75391 9.69092 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 11.0039H8.60349C8.32198 11.0039 8.09375 11.24 8.09375 11.5312C8.09375 11.8225 8.32198 12.0586 8.60349 12.0586H9.69092C9.97243 12.0586 10.2007 11.8225 10.2007 11.5312C10.2007 11.24 9.97243 11.0039 9.69092 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 13.2539H8.60349C8.32198 13.2539 8.09375 13.49 8.09375 13.7812C8.09375 14.0725 8.32198 14.3086 8.60349 14.3086H9.69092C9.97243 14.3086 10.2007 14.0725 10.2007 13.7812C10.2007 13.49 9.97243 13.2539 9.69092 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 8.75391H12.955C12.6735 8.75391 12.4453 8.99002 12.4453 9.28125C12.4453 9.57248 12.6735 9.80859 12.955 9.80859H14.0425C14.324 9.80859 14.5522 9.57248 14.5522 9.28125C14.5522 8.99002 14.324 8.75391 14.0425 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 11.0039H12.955C12.6735 11.0039 12.4453 11.24 12.4453 11.5312C12.4453 11.8225 12.6735 12.0586 12.955 12.0586H14.0425C14.324 12.0586 14.5522 11.8225 14.5522 11.5312C14.5522 11.24 14.324 11.0039 14.0425 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 13.2539H12.955C12.6735 13.2539 12.4453 13.49 12.4453 13.7812C12.4453 14.0725 12.6735 14.3086 12.955 14.3086H14.0425C14.324 14.3086 14.5522 14.0725 14.5522 13.7812C14.5522 13.49 14.324 13.2539 14.0425 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M16.319 2.28516H15.0956V1.40625C15.0956 1.11502 14.8674 0.878906 14.5859 0.878906C14.3044 0.878906 14.0762 1.11502 14.0762 1.40625V2.28516H9.65845V1.40625C9.65845 1.11502 9.43023 0.878906 9.14872 0.878906C8.86721 0.878906 8.63898 1.11502 8.63898 1.40625V2.28516H4.22127V1.40625C4.22127 1.11502 3.99304 0.878906 3.71153 0.878906C3.43002 0.878906 3.20179 1.11502 3.20179 1.40625V2.28516H1.97843C1.13522 2.28516 0.449219 2.99486 0.449219 3.86719V15.5391C0.449219 16.4114 1.13522 17.1211 1.97843 17.1211H16.319C17.1622 17.1211 17.8482 16.4114 17.8482 15.5391C17.8482 15.1987 17.8482 4.16338 17.8482 3.86719C17.8482 2.99486 17.1622 2.28516 16.319 2.28516ZM1.46869 3.86719C1.46869 3.57641 1.69736 3.33984 1.97843 3.33984H3.20179V4.21875C3.20179 4.50998 3.43002 4.74609 3.71153 4.74609C3.99304 4.74609 4.22127 4.50998 4.22127 4.21875V3.33984H8.63898V4.21875C8.63898 4.50998 8.86721 4.74609 9.14872 4.74609C9.43023 4.74609 9.65845 4.50998 9.65845 4.21875V3.33984H14.0762V4.21875C14.0762 4.50998 14.3044 4.74609 14.5859 4.74609C14.8674 4.74609 15.0956 4.50998 15.0956 4.21875V3.33984H16.319C16.6001 3.33984 16.8287 3.57641 16.8287 3.86719V5.94141H1.46869V3.86719ZM16.319 16.0664H1.97843C1.69736 16.0664 1.46869 15.8298 1.46869 15.5391V6.99609H16.8287V15.5391C16.8287 15.8298 16.6001 16.0664 16.319 16.0664Z"
                                                                                fill="#181818"/>
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0">
                                                                                <rect width="17.399"
                                                                                      height="18"
                                                                                      fill="white"
                                                                                      transform="translate(0.449219)"/>
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </Button>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">Təhsil dərəcəsi</span>
                                                            <Form.Label>
                                                                <Form.Control placeholder="Təhsil dərəcəsi daxil et"
                                                                              value={degree}
                                                                              onChange={(e => setDegree(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title">Sənədin nömrəsi</span>
                                                            <Form.Label>
                                                                <Form.Control placeholder="Sənədin nömrəsi daxil et"
                                                                              value={graduateFileNumber}
                                                                              onChange={(e => setGraduateFileNumber(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group>
                                                            <span className="input-title"> Sənədin verilmə tarixi</span>
                                                            <Form.Label className="relative m-0">
                                                                <DatePicker selected={startGraduateFile}
                                                                            dateFormat="dd-MM-yyyy"
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                            onChange={(date) => setStartGraduateFile(date)}/>
                                                                <Button className="btn-transparent">
                                                                    <svg width="18" height="18"
                                                                         viewBox="0 0 18 18" fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <g opacity="0.8"
                                                                           clipPath="url(#clip0)">
                                                                            <path
                                                                                d="M5.34327 8.75391H4.25583C3.97432 8.75391 3.74609 8.99002 3.74609 9.28125C3.74609 9.57248 3.97432 9.80859 4.25583 9.80859H5.34327C5.62478 9.80859 5.853 9.57248 5.853 9.28125C5.853 8.99002 5.62478 8.75391 5.34327 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M5.34327 11.0039H4.25583C3.97432 11.0039 3.74609 11.24 3.74609 11.5312C3.74609 11.8225 3.97432 12.0586 4.25583 12.0586H5.34327C5.62478 12.0586 5.853 11.8225 5.853 11.5312C5.853 11.24 5.62478 11.0039 5.34327 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M5.34327 13.2539H4.25583C3.97432 13.2539 3.74609 13.49 3.74609 13.7812C3.74609 14.0725 3.97432 14.3086 4.25583 14.3086H5.34327C5.62478 14.3086 5.853 14.0725 5.853 13.7812C5.853 13.49 5.62478 13.2539 5.34327 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 8.75391H8.60349C8.32198 8.75391 8.09375 8.99002 8.09375 9.28125C8.09375 9.57248 8.32198 9.80859 8.60349 9.80859H9.69092C9.97243 9.80859 10.2007 9.57248 10.2007 9.28125C10.2007 8.99002 9.97243 8.75391 9.69092 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 11.0039H8.60349C8.32198 11.0039 8.09375 11.24 8.09375 11.5312C8.09375 11.8225 8.32198 12.0586 8.60349 12.0586H9.69092C9.97243 12.0586 10.2007 11.8225 10.2007 11.5312C10.2007 11.24 9.97243 11.0039 9.69092 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 13.2539H8.60349C8.32198 13.2539 8.09375 13.49 8.09375 13.7812C8.09375 14.0725 8.32198 14.3086 8.60349 14.3086H9.69092C9.97243 14.3086 10.2007 14.0725 10.2007 13.7812C10.2007 13.49 9.97243 13.2539 9.69092 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 8.75391H12.955C12.6735 8.75391 12.4453 8.99002 12.4453 9.28125C12.4453 9.57248 12.6735 9.80859 12.955 9.80859H14.0425C14.324 9.80859 14.5522 9.57248 14.5522 9.28125C14.5522 8.99002 14.324 8.75391 14.0425 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 11.0039H12.955C12.6735 11.0039 12.4453 11.24 12.4453 11.5312C12.4453 11.8225 12.6735 12.0586 12.955 12.0586H14.0425C14.324 12.0586 14.5522 11.8225 14.5522 11.5312C14.5522 11.24 14.324 11.0039 14.0425 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 13.2539H12.955C12.6735 13.2539 12.4453 13.49 12.4453 13.7812C12.4453 14.0725 12.6735 14.3086 12.955 14.3086H14.0425C14.324 14.3086 14.5522 14.0725 14.5522 13.7812C14.5522 13.49 14.324 13.2539 14.0425 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M16.319 2.28516H15.0956V1.40625C15.0956 1.11502 14.8674 0.878906 14.5859 0.878906C14.3044 0.878906 14.0762 1.11502 14.0762 1.40625V2.28516H9.65845V1.40625C9.65845 1.11502 9.43023 0.878906 9.14872 0.878906C8.86721 0.878906 8.63898 1.11502 8.63898 1.40625V2.28516H4.22127V1.40625C4.22127 1.11502 3.99304 0.878906 3.71153 0.878906C3.43002 0.878906 3.20179 1.11502 3.20179 1.40625V2.28516H1.97843C1.13522 2.28516 0.449219 2.99486 0.449219 3.86719V15.5391C0.449219 16.4114 1.13522 17.1211 1.97843 17.1211H16.319C17.1622 17.1211 17.8482 16.4114 17.8482 15.5391C17.8482 15.1987 17.8482 4.16338 17.8482 3.86719C17.8482 2.99486 17.1622 2.28516 16.319 2.28516ZM1.46869 3.86719C1.46869 3.57641 1.69736 3.33984 1.97843 3.33984H3.20179V4.21875C3.20179 4.50998 3.43002 4.74609 3.71153 4.74609C3.99304 4.74609 4.22127 4.50998 4.22127 4.21875V3.33984H8.63898V4.21875C8.63898 4.50998 8.86721 4.74609 9.14872 4.74609C9.43023 4.74609 9.65845 4.50998 9.65845 4.21875V3.33984H14.0762V4.21875C14.0762 4.50998 14.3044 4.74609 14.5859 4.74609C14.8674 4.74609 15.0956 4.50998 15.0956 4.21875V3.33984H16.319C16.6001 3.33984 16.8287 3.57641 16.8287 3.86719V5.94141H1.46869V3.86719ZM16.319 16.0664H1.97843C1.69736 16.0664 1.46869 15.8298 1.46869 15.5391V6.99609H16.8287V15.5391C16.8287 15.8298 16.6001 16.0664 16.319 16.0664Z"
                                                                                fill="#181818"/>
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0">
                                                                                <rect width="17.399"
                                                                                      height="18"
                                                                                      fill="white"
                                                                                      transform="translate(0.449219)"/>
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </Button>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col xs={6}>
                                                        <Form.Group>
                                                            <span className="input-title">Təhsil forması</span>
                                                            <Select
                                                                placeholder="Təhsil forması seçin"
                                                                value={selectedEducationType}
                                                                onChange={(val) => {
                                                                    setSelectedEducationType(val);
                                                                }}
                                                                options={educationTypeOptions}
                                                                getOptionLabel={(option) => (option.label)}
                                                                styles={customStyles}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6
                                                    }>
                                                        <Form.Group>
                                                            <span className="input-title">Nostrifikasiya şəhadətnaməsinin nömrəsi</span>
                                                            <Form.Label>
                                                                <Form.Control
                                                                    placeholder="Nostrifikasiya şəhadətnaməsinin nömrəsi daxil et"
                                                                    value={nostrificationNumber}
                                                                    onChange={(e => setNostrificationNumber(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div className="add-block">
                                            <div className="block-title">
                                                Sertifikat ( vəsiqə)
                                            </div>
                                            <div className="block-inn">
                                                <div className="addition-content">
                                                    {
                                                        certificateArr.map((item, index) =>
                                                            <div key={uid(item, index)} className={index == 0 ? '' : 'add-item'}>
                                                                {
                                                                    index == 0 ? null :
                                                                        <div className="add-item-top">
                                                                            <p className="m-0"> Sertifikat ( vəsiqə {index + 1} )</p>
                                                                            <Button
                                                                                className="btn-transparent btn-remove flex-center"
                                                                                onClick={() => {
                                                                                    certificateArr.splice(index, 1);
                                                                                    setCertificateArr([...certificateArr], certificateArr)
                                                                                }}>
                                                                                <svg width="14" height="14"
                                                                                     viewBox="0 0 14 14" fill="none"
                                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                                    <path
                                                                                        d="M11.1665 2.69336L10.2739 12.8645H3.7302L2.8378 2.69336L1.70703 2.79248L2.61572 13.1481C2.66354 13.6254 3.07769 13.9997 3.5588 13.9997H10.4453C10.9262 13.9997 11.3405 13.6256 11.3892 13.1413L12.2973 2.79248L11.1665 2.69336Z"
                                                                                        fill="#CF3131"/>
                                                                                    <path
                                                                                        d="M9.08077 0H4.91861C4.397 0 3.97266 0.424348 3.97266 0.945957V2.74326H5.10778V1.13512H8.89155V2.74323H10.0267V0.94593C10.0267 0.424348 9.60238 0 9.08077 0Z"
                                                                                        fill="#CF3131"/>
                                                                                    <path
                                                                                        d="M13.0507 2.17578H0.942574C0.629078 2.17578 0.375 2.42986 0.375 2.74336C0.375 3.05685 0.629078 3.31093 0.942574 3.31093H13.0507C13.3642 3.31093 13.6183 3.05685 13.6183 2.74336C13.6183 2.42986 13.3642 2.17578 13.0507 2.17578Z"
                                                                                        fill="#CF3131"/>
                                                                                </svg>
                                                                                <span>Sil</span>
                                                                            </Button>
                                                                        </div>
                                                                }
                                                                <Row>
                                                                    <Col xs={6}>
                                                                        <Form.Group>
                                                                            <span className="input-title">Sertifikatın (vəsiqənin) adı</span>
                                                                            <Form.Label>
                                                                                <Form.Control placeholder="Adı daxil edin"
                                                                                              onChange={(e) => {
                                                                                                  certificateArr[index].name = e.target.value;
                                                                                                  setCertificateArr([...certificateArr], certificateArr)
                                                                                              }}/>
                                                                            </Form.Label>
                                                                        </Form.Group>
                                                                    </Col>

                                                                    <Col xs={6}>
                                                                        <Form.Group>
                                                                <span
                                                                    className="input-title">Qüvvədə olma müddəti</span>
                                                                            <Form.Label className="relative m-0">
                                                                                <DatePicker
                                                                                    selected={expiredCertificateDate}
                                                                                    dateFormat="dd-MM-yyyy"
                                                                                    showMonthDropdown
                                                                                    showYearDropdown
                                                                                    dropdownMode="select"
                                                                                    onChange={(date) => {
                                                                                        setExpiredCertificateDate(date);
                                                                                        certificateArr[index].endDate = moment(date).format("DD-MM-YYYY");
                                                                                        setCertificateArr([...certificateArr], certificateArr)
                                                                                    }}/>
                                                                                <Button className="btn-transparent">
                                                                                    <svg width="18" height="18"
                                                                                         viewBox="0 0 18 18" fill="none"
                                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                                        <g opacity="0.8"
                                                                                           clipPath="url(#clip0)">
                                                                                            <path
                                                                                                d="M5.34327 8.75391H4.25583C3.97432 8.75391 3.74609 8.99002 3.74609 9.28125C3.74609 9.57248 3.97432 9.80859 4.25583 9.80859H5.34327C5.62478 9.80859 5.853 9.57248 5.853 9.28125C5.853 8.99002 5.62478 8.75391 5.34327 8.75391Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M5.34327 11.0039H4.25583C3.97432 11.0039 3.74609 11.24 3.74609 11.5312C3.74609 11.8225 3.97432 12.0586 4.25583 12.0586H5.34327C5.62478 12.0586 5.853 11.8225 5.853 11.5312C5.853 11.24 5.62478 11.0039 5.34327 11.0039Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M5.34327 13.2539H4.25583C3.97432 13.2539 3.74609 13.49 3.74609 13.7812C3.74609 14.0725 3.97432 14.3086 4.25583 14.3086H5.34327C5.62478 14.3086 5.853 14.0725 5.853 13.7812C5.853 13.49 5.62478 13.2539 5.34327 13.2539Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M9.69092 8.75391H8.60349C8.32198 8.75391 8.09375 8.99002 8.09375 9.28125C8.09375 9.57248 8.32198 9.80859 8.60349 9.80859H9.69092C9.97243 9.80859 10.2007 9.57248 10.2007 9.28125C10.2007 8.99002 9.97243 8.75391 9.69092 8.75391Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M9.69092 11.0039H8.60349C8.32198 11.0039 8.09375 11.24 8.09375 11.5312C8.09375 11.8225 8.32198 12.0586 8.60349 12.0586H9.69092C9.97243 12.0586 10.2007 11.8225 10.2007 11.5312C10.2007 11.24 9.97243 11.0039 9.69092 11.0039Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M9.69092 13.2539H8.60349C8.32198 13.2539 8.09375 13.49 8.09375 13.7812C8.09375 14.0725 8.32198 14.3086 8.60349 14.3086H9.69092C9.97243 14.3086 10.2007 14.0725 10.2007 13.7812C10.2007 13.49 9.97243 13.2539 9.69092 13.2539Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M14.0425 8.75391H12.955C12.6735 8.75391 12.4453 8.99002 12.4453 9.28125C12.4453 9.57248 12.6735 9.80859 12.955 9.80859H14.0425C14.324 9.80859 14.5522 9.57248 14.5522 9.28125C14.5522 8.99002 14.324 8.75391 14.0425 8.75391Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M14.0425 11.0039H12.955C12.6735 11.0039 12.4453 11.24 12.4453 11.5312C12.4453 11.8225 12.6735 12.0586 12.955 12.0586H14.0425C14.324 12.0586 14.5522 11.8225 14.5522 11.5312C14.5522 11.24 14.324 11.0039 14.0425 11.0039Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M14.0425 13.2539H12.955C12.6735 13.2539 12.4453 13.49 12.4453 13.7812C12.4453 14.0725 12.6735 14.3086 12.955 14.3086H14.0425C14.324 14.3086 14.5522 14.0725 14.5522 13.7812C14.5522 13.49 14.324 13.2539 14.0425 13.2539Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M16.319 2.28516H15.0956V1.40625C15.0956 1.11502 14.8674 0.878906 14.5859 0.878906C14.3044 0.878906 14.0762 1.11502 14.0762 1.40625V2.28516H9.65845V1.40625C9.65845 1.11502 9.43023 0.878906 9.14872 0.878906C8.86721 0.878906 8.63898 1.11502 8.63898 1.40625V2.28516H4.22127V1.40625C4.22127 1.11502 3.99304 0.878906 3.71153 0.878906C3.43002 0.878906 3.20179 1.11502 3.20179 1.40625V2.28516H1.97843C1.13522 2.28516 0.449219 2.99486 0.449219 3.86719V15.5391C0.449219 16.4114 1.13522 17.1211 1.97843 17.1211H16.319C17.1622 17.1211 17.8482 16.4114 17.8482 15.5391C17.8482 15.1987 17.8482 4.16338 17.8482 3.86719C17.8482 2.99486 17.1622 2.28516 16.319 2.28516ZM1.46869 3.86719C1.46869 3.57641 1.69736 3.33984 1.97843 3.33984H3.20179V4.21875C3.20179 4.50998 3.43002 4.74609 3.71153 4.74609C3.99304 4.74609 4.22127 4.50998 4.22127 4.21875V3.33984H8.63898V4.21875C8.63898 4.50998 8.86721 4.74609 9.14872 4.74609C9.43023 4.74609 9.65845 4.50998 9.65845 4.21875V3.33984H14.0762V4.21875C14.0762 4.50998 14.3044 4.74609 14.5859 4.74609C14.8674 4.74609 15.0956 4.50998 15.0956 4.21875V3.33984H16.319C16.6001 3.33984 16.8287 3.57641 16.8287 3.86719V5.94141H1.46869V3.86719ZM16.319 16.0664H1.97843C1.69736 16.0664 1.46869 15.8298 1.46869 15.5391V6.99609H16.8287V15.5391C16.8287 15.8298 16.6001 16.0664 16.319 16.0664Z"
                                                                                                fill="#181818"/>
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0">
                                                                                                <rect width="17.399"
                                                                                                      height="18"
                                                                                                      fill="white"
                                                                                                      transform="translate(0.449219)"/>
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg>
                                                                                </Button>
                                                                            </Form.Label>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>

                                                            </div>
                                                        )
                                                    }
                                                    <div className="flex-end">
                                                        <Button className="btn-main" onClick={() => addCertificate()}>
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M15.8346 10.8337H10.8346V15.8337H9.16797V10.8337H4.16797V9.16699H9.16797V4.16699H10.8346V9.16699H15.8346V10.8337Z"
                                                                    fill="white"/>
                                                            </svg>
                                                            Əlavə et
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="add-block">
                                            <div className="block-title">
                                                Dövlət təltifləri, fəxri adlar
                                            </div>
                                            <div className="block-inn">
                                                <div className="addition-content">
                                                    {
                                                        rewardArr.map((item, index) =>
                                                            <div key={uid(item, index)} className={index == 0 ? '' : 'add-item'}>
                                                                {
                                                                    index == 0 ? null :
                                                                        <div className="add-item-top">
                                                                            <p className="m-0"> Sertifikat ( vəsiqə {index + 1} )</p>
                                                                            <Button
                                                                                className="btn-transparent btn-remove flex-center"
                                                                                onClick={() => {
                                                                                    rewardArr.splice(index, 1);
                                                                                    setRewardArr([...rewardArr], rewardArr)
                                                                                }}>
                                                                                <svg width="14" height="14"
                                                                                     viewBox="0 0 14 14" fill="none"
                                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                                    <path
                                                                                        d="M11.1665 2.69336L10.2739 12.8645H3.7302L2.8378 2.69336L1.70703 2.79248L2.61572 13.1481C2.66354 13.6254 3.07769 13.9997 3.5588 13.9997H10.4453C10.9262 13.9997 11.3405 13.6256 11.3892 13.1413L12.2973 2.79248L11.1665 2.69336Z"
                                                                                        fill="#CF3131"/>
                                                                                    <path
                                                                                        d="M9.08077 0H4.91861C4.397 0 3.97266 0.424348 3.97266 0.945957V2.74326H5.10778V1.13512H8.89155V2.74323H10.0267V0.94593C10.0267 0.424348 9.60238 0 9.08077 0Z"
                                                                                        fill="#CF3131"/>
                                                                                    <path
                                                                                        d="M13.0507 2.17578H0.942574C0.629078 2.17578 0.375 2.42986 0.375 2.74336C0.375 3.05685 0.629078 3.31093 0.942574 3.31093H13.0507C13.3642 3.31093 13.6183 3.05685 13.6183 2.74336C13.6183 2.42986 13.3642 2.17578 13.0507 2.17578Z"
                                                                                        fill="#CF3131"/>
                                                                                </svg>
                                                                                <span>Sil</span>
                                                                            </Button>
                                                                        </div>
                                                                }
                                                                <Row key={index}>
                                                                    <Col xs={4}>
                                                                        <Form.Group>
                                                                        <span
                                                                            className="input-title">Təltifin adı</span>
                                                                            <Form.Label>
                                                                                <Select
                                                                                    placeholder="Təhsil müəssəsini seçin"
                                                                                    onChange={(val) => {
                                                                                        rewardArr[index].name = val.name;
                                                                                        setRewardArr([...rewardArr], rewardArr)
                                                                                    }}
                                                                                    options={reward}
                                                                                    getOptionLabel={(option) => (option.name)}
                                                                                    styles={customStyles}
                                                                                />
                                                                            </Form.Label>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col xs={4}>
                                                                        <Form.Group>
                                                            <span
                                                                className="input-title">Təltifi verən orqanın adı</span>
                                                                            <Form.Label>
                                                                                <Select
                                                                                    placeholder="Təhsil müəssəsini seçin"
                                                                                    onChange={(val) => {
                                                                                        rewardArr[index].organization = val.name;
                                                                                        setRewardArr([...rewardArr], rewardArr)
                                                                                    }}
                                                                                    options={rewardOrganization}
                                                                                    getOptionLabel={(option) => (option.name)}
                                                                                    styles={customStyles}
                                                                                />
                                                                            </Form.Label>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col xs={4}>
                                                                        <Form.Group>
                                                                <span
                                                                    className="input-title">Təltifin verilmə tarixi</span>
                                                                            <Form.Label className="relative m-0">
                                                                                <DatePicker selected={startRewardDate}
                                                                                            dateFormat="dd-MM-yyyy"
                                                                                            showMonthDropdown
                                                                                            showYearDropdown
                                                                                            dropdownMode="select"
                                                                                            onChange={(date) => {
                                                                                                setStartRewardDate(date)
                                                                                                rewardArr[index].startDate = moment(date).format("DD-MM-YYYY");
                                                                                                setRewardArr([...rewardArr], rewardArr)
                                                                                            }}/>
                                                                                <Button className="btn-transparent">
                                                                                    <svg width="18" height="18"
                                                                                         viewBox="0 0 18 18" fill="none"
                                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                                        <g opacity="0.8"
                                                                                           clipPath="url(#clip0)">
                                                                                            <path
                                                                                                d="M5.34327 8.75391H4.25583C3.97432 8.75391 3.74609 8.99002 3.74609 9.28125C3.74609 9.57248 3.97432 9.80859 4.25583 9.80859H5.34327C5.62478 9.80859 5.853 9.57248 5.853 9.28125C5.853 8.99002 5.62478 8.75391 5.34327 8.75391Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M5.34327 11.0039H4.25583C3.97432 11.0039 3.74609 11.24 3.74609 11.5312C3.74609 11.8225 3.97432 12.0586 4.25583 12.0586H5.34327C5.62478 12.0586 5.853 11.8225 5.853 11.5312C5.853 11.24 5.62478 11.0039 5.34327 11.0039Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M5.34327 13.2539H4.25583C3.97432 13.2539 3.74609 13.49 3.74609 13.7812C3.74609 14.0725 3.97432 14.3086 4.25583 14.3086H5.34327C5.62478 14.3086 5.853 14.0725 5.853 13.7812C5.853 13.49 5.62478 13.2539 5.34327 13.2539Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M9.69092 8.75391H8.60349C8.32198 8.75391 8.09375 8.99002 8.09375 9.28125C8.09375 9.57248 8.32198 9.80859 8.60349 9.80859H9.69092C9.97243 9.80859 10.2007 9.57248 10.2007 9.28125C10.2007 8.99002 9.97243 8.75391 9.69092 8.75391Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M9.69092 11.0039H8.60349C8.32198 11.0039 8.09375 11.24 8.09375 11.5312C8.09375 11.8225 8.32198 12.0586 8.60349 12.0586H9.69092C9.97243 12.0586 10.2007 11.8225 10.2007 11.5312C10.2007 11.24 9.97243 11.0039 9.69092 11.0039Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M9.69092 13.2539H8.60349C8.32198 13.2539 8.09375 13.49 8.09375 13.7812C8.09375 14.0725 8.32198 14.3086 8.60349 14.3086H9.69092C9.97243 14.3086 10.2007 14.0725 10.2007 13.7812C10.2007 13.49 9.97243 13.2539 9.69092 13.2539Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M14.0425 8.75391H12.955C12.6735 8.75391 12.4453 8.99002 12.4453 9.28125C12.4453 9.57248 12.6735 9.80859 12.955 9.80859H14.0425C14.324 9.80859 14.5522 9.57248 14.5522 9.28125C14.5522 8.99002 14.324 8.75391 14.0425 8.75391Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M14.0425 11.0039H12.955C12.6735 11.0039 12.4453 11.24 12.4453 11.5312C12.4453 11.8225 12.6735 12.0586 12.955 12.0586H14.0425C14.324 12.0586 14.5522 11.8225 14.5522 11.5312C14.5522 11.24 14.324 11.0039 14.0425 11.0039Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M14.0425 13.2539H12.955C12.6735 13.2539 12.4453 13.49 12.4453 13.7812C12.4453 14.0725 12.6735 14.3086 12.955 14.3086H14.0425C14.324 14.3086 14.5522 14.0725 14.5522 13.7812C14.5522 13.49 14.324 13.2539 14.0425 13.2539Z"
                                                                                                fill="#181818"/>
                                                                                            <path
                                                                                                d="M16.319 2.28516H15.0956V1.40625C15.0956 1.11502 14.8674 0.878906 14.5859 0.878906C14.3044 0.878906 14.0762 1.11502 14.0762 1.40625V2.28516H9.65845V1.40625C9.65845 1.11502 9.43023 0.878906 9.14872 0.878906C8.86721 0.878906 8.63898 1.11502 8.63898 1.40625V2.28516H4.22127V1.40625C4.22127 1.11502 3.99304 0.878906 3.71153 0.878906C3.43002 0.878906 3.20179 1.11502 3.20179 1.40625V2.28516H1.97843C1.13522 2.28516 0.449219 2.99486 0.449219 3.86719V15.5391C0.449219 16.4114 1.13522 17.1211 1.97843 17.1211H16.319C17.1622 17.1211 17.8482 16.4114 17.8482 15.5391C17.8482 15.1987 17.8482 4.16338 17.8482 3.86719C17.8482 2.99486 17.1622 2.28516 16.319 2.28516ZM1.46869 3.86719C1.46869 3.57641 1.69736 3.33984 1.97843 3.33984H3.20179V4.21875C3.20179 4.50998 3.43002 4.74609 3.71153 4.74609C3.99304 4.74609 4.22127 4.50998 4.22127 4.21875V3.33984H8.63898V4.21875C8.63898 4.50998 8.86721 4.74609 9.14872 4.74609C9.43023 4.74609 9.65845 4.50998 9.65845 4.21875V3.33984H14.0762V4.21875C14.0762 4.50998 14.3044 4.74609 14.5859 4.74609C14.8674 4.74609 15.0956 4.50998 15.0956 4.21875V3.33984H16.319C16.6001 3.33984 16.8287 3.57641 16.8287 3.86719V5.94141H1.46869V3.86719ZM16.319 16.0664H1.97843C1.69736 16.0664 1.46869 15.8298 1.46869 15.5391V6.99609H16.8287V15.5391C16.8287 15.8298 16.6001 16.0664 16.319 16.0664Z"
                                                                                                fill="#181818"/>
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0">
                                                                                                <rect width="17.399"
                                                                                                      height="18"
                                                                                                      fill="white"
                                                                                                      transform="translate(0.449219)"/>
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg>
                                                                                </Button>
                                                                            </Form.Label>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )
                                                    }
                                                    <div className="flex-end">
                                                        <Button className="btn-main" onClick={() => addReward()}>
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M15.8346 10.8337H10.8346V15.8337H9.16797V10.8337H4.16797V9.16699H9.16797V4.16699H10.8346V9.16699H15.8346V10.8337Z"
                                                                    fill="white"/>
                                                            </svg>
                                                            Əlavə et
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="add-block">
                                            <div className="block-title">
                                                Sürücülük vəsiqəsi
                                            </div>
                                            <div className="block-inn">
                                                <Row>
                                                    <Col xs={6}>
                                                        <Form.Group>
                                                            <span className="input-title">Kateqoriya</span>
                                                            <Form.Label>
                                                                <Select
                                                                    placeholder="Kateqoriya daxil edin"
                                                                    value={selectedDriverLicence}
                                                                    onChange={(val) => {
                                                                        setSelectedDriverLicence(val)
                                                                    }}
                                                                    options={driverLicenceOptions}
                                                                    getOptionLabel={(option) => (option.label)}
                                                                    styles={customStyles}
                                                                />
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col xs={6}>
                                                        <Form.Group>
                                                                <span
                                                                    className="input-title">Qüvvədə olma müddəti</span>
                                                            <Form.Label className="relative m-0">
                                                                <DatePicker selected={expiredDriverLicenceDate}
                                                                            dateFormat="dd-MM-yyyy"
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                            onChange={(date) => setExpiredDriverLicenceDate(date)}/>
                                                                <Button className="btn-transparent">
                                                                    <svg width="18" height="18"
                                                                         viewBox="0 0 18 18" fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <g opacity="0.8"
                                                                           clipPath="url(#clip0)">
                                                                            <path
                                                                                d="M5.34327 8.75391H4.25583C3.97432 8.75391 3.74609 8.99002 3.74609 9.28125C3.74609 9.57248 3.97432 9.80859 4.25583 9.80859H5.34327C5.62478 9.80859 5.853 9.57248 5.853 9.28125C5.853 8.99002 5.62478 8.75391 5.34327 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M5.34327 11.0039H4.25583C3.97432 11.0039 3.74609 11.24 3.74609 11.5312C3.74609 11.8225 3.97432 12.0586 4.25583 12.0586H5.34327C5.62478 12.0586 5.853 11.8225 5.853 11.5312C5.853 11.24 5.62478 11.0039 5.34327 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M5.34327 13.2539H4.25583C3.97432 13.2539 3.74609 13.49 3.74609 13.7812C3.74609 14.0725 3.97432 14.3086 4.25583 14.3086H5.34327C5.62478 14.3086 5.853 14.0725 5.853 13.7812C5.853 13.49 5.62478 13.2539 5.34327 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 8.75391H8.60349C8.32198 8.75391 8.09375 8.99002 8.09375 9.28125C8.09375 9.57248 8.32198 9.80859 8.60349 9.80859H9.69092C9.97243 9.80859 10.2007 9.57248 10.2007 9.28125C10.2007 8.99002 9.97243 8.75391 9.69092 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 11.0039H8.60349C8.32198 11.0039 8.09375 11.24 8.09375 11.5312C8.09375 11.8225 8.32198 12.0586 8.60349 12.0586H9.69092C9.97243 12.0586 10.2007 11.8225 10.2007 11.5312C10.2007 11.24 9.97243 11.0039 9.69092 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M9.69092 13.2539H8.60349C8.32198 13.2539 8.09375 13.49 8.09375 13.7812C8.09375 14.0725 8.32198 14.3086 8.60349 14.3086H9.69092C9.97243 14.3086 10.2007 14.0725 10.2007 13.7812C10.2007 13.49 9.97243 13.2539 9.69092 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 8.75391H12.955C12.6735 8.75391 12.4453 8.99002 12.4453 9.28125C12.4453 9.57248 12.6735 9.80859 12.955 9.80859H14.0425C14.324 9.80859 14.5522 9.57248 14.5522 9.28125C14.5522 8.99002 14.324 8.75391 14.0425 8.75391Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 11.0039H12.955C12.6735 11.0039 12.4453 11.24 12.4453 11.5312C12.4453 11.8225 12.6735 12.0586 12.955 12.0586H14.0425C14.324 12.0586 14.5522 11.8225 14.5522 11.5312C14.5522 11.24 14.324 11.0039 14.0425 11.0039Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M14.0425 13.2539H12.955C12.6735 13.2539 12.4453 13.49 12.4453 13.7812C12.4453 14.0725 12.6735 14.3086 12.955 14.3086H14.0425C14.324 14.3086 14.5522 14.0725 14.5522 13.7812C14.5522 13.49 14.324 13.2539 14.0425 13.2539Z"
                                                                                fill="#181818"/>
                                                                            <path
                                                                                d="M16.319 2.28516H15.0956V1.40625C15.0956 1.11502 14.8674 0.878906 14.5859 0.878906C14.3044 0.878906 14.0762 1.11502 14.0762 1.40625V2.28516H9.65845V1.40625C9.65845 1.11502 9.43023 0.878906 9.14872 0.878906C8.86721 0.878906 8.63898 1.11502 8.63898 1.40625V2.28516H4.22127V1.40625C4.22127 1.11502 3.99304 0.878906 3.71153 0.878906C3.43002 0.878906 3.20179 1.11502 3.20179 1.40625V2.28516H1.97843C1.13522 2.28516 0.449219 2.99486 0.449219 3.86719V15.5391C0.449219 16.4114 1.13522 17.1211 1.97843 17.1211H16.319C17.1622 17.1211 17.8482 16.4114 17.8482 15.5391C17.8482 15.1987 17.8482 4.16338 17.8482 3.86719C17.8482 2.99486 17.1622 2.28516 16.319 2.28516ZM1.46869 3.86719C1.46869 3.57641 1.69736 3.33984 1.97843 3.33984H3.20179V4.21875C3.20179 4.50998 3.43002 4.74609 3.71153 4.74609C3.99304 4.74609 4.22127 4.50998 4.22127 4.21875V3.33984H8.63898V4.21875C8.63898 4.50998 8.86721 4.74609 9.14872 4.74609C9.43023 4.74609 9.65845 4.50998 9.65845 4.21875V3.33984H14.0762V4.21875C14.0762 4.50998 14.3044 4.74609 14.5859 4.74609C14.8674 4.74609 15.0956 4.50998 15.0956 4.21875V3.33984H16.319C16.6001 3.33984 16.8287 3.57641 16.8287 3.86719V5.94141H1.46869V3.86719ZM16.319 16.0664H1.97843C1.69736 16.0664 1.46869 15.8298 1.46869 15.5391V6.99609H16.8287V15.5391C16.8287 15.8298 16.6001 16.0664 16.319 16.0664Z"
                                                                                fill="#181818"/>
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0">
                                                                                <rect width="17.399"
                                                                                      height="18"
                                                                                      fill="white"
                                                                                      transform="translate(0.449219)"/>
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </Button>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div className="add-block">
                                            <div className="block-title">
                                                Sosial sığorta şəhadətnaməsi ( burdan goturulecek, gedecek umumi
                                                melumata)
                                            </div>
                                            <div className="block-inn">
                                                <Row>
                                                    <Col xs={6}>
                                                        <Form.Group>
                                                            <span
                                                                className="input-title">S.s şəhadətnaməsinin nömrəsi</span>
                                                            <Form.Label>
                                                                <Form.Control
                                                                    placeholder="Nömrəni daxil edin"
                                                                    value={warrantyNumber}
                                                                    onChange={(e => setWarrantyNumber(e.target.value))}/>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Group>
                                                                <span
                                                                    className="input-title">İşçinin aid oldu kvota üzrə məlumatlar</span>
                                                            <Form.Label className="relative m-0">
                                                                <Select
                                                                    placeholder="Yetkinlik yaşına çatmamış uşaqları tərbiyə edən tək və çoxuşaqlı valideynlər"
                                                                    value={selectedQuota}
                                                                    onChange={(val) => {
                                                                        setSelectedQuota(val);
                                                                        setQuotaArr(val)
                                                                    }}
                                                                    isMulti
                                                                    options={quota}
                                                                    getOptionLabel={(option) => (option.key)}
                                                                    styles={customStyles}
                                                                />
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <div className="radio-question">
                                                    <Row>
                                                        <Col xs={6}>
                                                            <div className="radio-content">
                                                                <h5>Məhkum olmusunuzmu?</h5>
                                                                <div className="flex-start">
                                                                    <div className="radio-block">
                                                                        <label className="radio-label">
                                                                            <input type="radio" name="prisoner"
                                                                                   checked={checkPrisoner}
                                                                                   onChange={(e) => {
                                                                                       setCheckPrisoner(true)
                                                                                   }}/>
                                                                            <span className="radio-mark"></span>
                                                                        </label>
                                                                        <span className="radio-title">Bəli</span>
                                                                    </div>
                                                                    <div className="radio-block">
                                                                        <label className="radio-label">
                                                                            <input type="radio" name="prisoner"
                                                                                   checked={!checkPrisoner}
                                                                                   onChange={(e) => {
                                                                                       setCheckPrisoner(false)
                                                                                   }}/>
                                                                            <span className="radio-mark"></span>
                                                                        </label>
                                                                        <span className="radio-title">Xeyr</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <div className="radio-content">
                                                                <h5>Həmkarlar ittifaqının üzvüsünüzmü?</h5>
                                                                <div className="flex-start">
                                                                    <div className="radio-block">
                                                                        <label className="radio-label">
                                                                            <input type="radio" name="colleague"
                                                                                   checked={checkColleague}
                                                                                   onChange={(e) => {
                                                                                       setCheckColleague(true)
                                                                                   }}/>
                                                                            <span className="radio-mark"></span>
                                                                        </label>
                                                                        <span className="radio-title">Bəli</span>
                                                                    </div>
                                                                    <div className="radio-block">
                                                                        <label className="radio-label">
                                                                            <input type="radio" name="colleague"
                                                                                   checked={!checkColleague}
                                                                                   onChange={(e) => {
                                                                                       setCheckColleague(false)
                                                                                   }}/>
                                                                            <span className="radio-mark"></span>
                                                                        </label>
                                                                        <span className="radio-title">Xeyr</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-vertical-center">
                                            <Button className="btn-effect" onClick={() => sendDataAcademic()}>
                                                Yadda saxla
                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            </Tab>
                        </Tabs>
                    </Container>
                </div>
            </div>
        </Aux>

    );
}

export default CreateEmployee
