import React from 'react';
import Aux from '../../hoc/Auxiliary'
import ProtectedRoute from "./ProtectedRoute";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Login from "../../container/Login/Login";
import StaffSchedule from "../../container/Staff/StaffSchedule/StaffSchedule";
import CreateStaff from "../../container/Staff/CreateStaff/CreateStaff";
import EditStaff from "../../container/Staff/EditStaff/EditStaff";
import EmployeeSchedule from "../../container/Employee/EmployeeSchedule/EmployeeSchedule";
import CreateEmployee from "../../container/Employee/CreateEmployee/CreateEmployee";
import EditEmployee from "../../container/Employee/EditEmployee/EditEmployee";
export default class Layout extends React.Component {
    render() {
        return (
            <Aux>
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <Login/>
                        </Route>
                        <ProtectedRoute path="/employeeSchedule" component={EmployeeSchedule}/>
                        <ProtectedRoute path="/createEmployee" component={CreateEmployee}/>
                        <ProtectedRoute path="/editEmployee" component={EditEmployee}/>
                        <ProtectedRoute path="/staffSchedule" component={StaffSchedule}/>
                        <ProtectedRoute path="/editStaff" component={EditStaff}/>
                        <ProtectedRoute path="/createStaff" component={CreateStaff}/>
                    </Switch>
                </Router>
            </Aux>
        )
    }
}
